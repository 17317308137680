//
//  Product Lists
//  _____________________________________________

.page-products {

    .page-main {
        @extend .s_container--large;

        #amasty-shopby-overlay {
            background-image: url(../images/icons/loader.svg) !important;
            background-repeat: no-repeat !important;
            background-position: center 100px !important;
            opacity: 1 !important;
            background-color: rgba($c-white, 0.8) !important;
        }

        .columns {
            padding-top: 40px;
            display: flex;
            flex-wrap: nowrap;

            @media screen and (max-width: $screen__l) {
                flex-wrap: wrap;
            }
        }

        .sidebar-additional {
            display: none;
        }

        .sidebar-main {
            min-width: 300px;
            flex-grow: 0;
            flex-basis: 0;

            .sorter-mobile {
                display: block;
                padding: 40px 32px;

                label.sorter-label {
                    font-weight: 700;
                    line-height: 1.2;
                    font-size: 1.4rem;
                }

                &__options {
                    display: flex;
                    flex-direction: column;
                    margin-top: 12px;

                    .option {
                        display: inline-block;
                        position: relative;
                        font-size: 1.4rem;
                        padding-bottom: 6px;
                    }

                    span {
                        display: block;
                        width: 100%;
                        padding-right: 24px;
                    }

                    input {
                        width: 18px;
                        height: 18px;
                        position: absolute;
                        right: 0;
                        top: 1px;

                        &:before {
                            width: 18px;
                            height: 18px;
                            -webkit-background-size: 18px;
                            background-size: 18px;
                        }
                    }
                }
            }

            @media screen and (max-width: $screen__l) {
                min-width: 0;
                flex-grow: 1;
                flex-basis: 100%;
            }

            .am-show-form {
                display: inline-block;
                padding: 0;
            }

            .filter-title,
            .filter-subtitle {
                display: none;
            }

            .filter-current {
                display: none;
            }

            .filter-actions {
                margin-bottom: 23px;

                > a {
                    display: inline-block;
                    border-radius: 20px;
                    padding: 4px 24px 4px 9px;
                    background-color: $c-black2;
                    color: $c-white;
                    font-size: 12px;
                    position: relative;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-6px);
                        background-image: url('../images/icons/close-filters.svg');
                        -webkit-background-size: 10px;
                        background-size: 10px;
                        background-repeat: no-repeat;
                        width: 10px;
                        height: 10px;
                    }
                }
            }

            .filter-options {

                &-title {
                    position: relative;
                    padding-right: 20px;
                    cursor: pointer;
                    padding-top: 24px;
                    border-top: 1px solid $c-chromatic2;
                    margin: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 22px;
                        background-image: url('../images/icons/plus-small.svg');
                        -webkit-background-size: 16px;
                        background-size: 16px;
                        width: 16px;
                        height: 16px;
                        background-repeat: no-repeat;
                    }

                    &:hover,
                    &.opened {

                        &:after {
                            background-image: url('../images/icons/minus-small.svg');
                        }
                    }
                }

                &-content {
                    height: 0;
                    overflow: hidden;
                    transition: height .24s ease-in-out;
                    margin-bottom: 23px;

                    > form {
                        margin-top: 12px;
                        display: inline-block;
                        width: 100%;

                        &[data-amshopby-filter="size"] {
                            width: auto;
                        }

                        > ol {
                            padding-top: 0;
                        }

                        @media screen and (max-width: $screen__l) {
                            width: 100%;

                            > ol {
                                width: 100%;

                                a {
                                    display: block;
                                    position: relative;
                                    padding-right: 24px;
                                    font-weight: normal;

                                    input {
                                        position: absolute;
                                        right: 0;
                                        top: 1px;
                                    }
                                }
                            }
                        }
                    }

                    .swatch-attribute {
                        margin-bottom: 0;

                        &-options {
                            margin-top: 12px;
                        }
                    }

                    .swatch-attribute.size {

                        .swatch-option.text {

                            &:hover {
                                border: 1px solid $c-black2;
                            }
                        }
                    }

                    .swatch-option.image,
                    .swatch-option.color {
                        width: 24px;
                        height: 24px;
                    }

                    .items {
                        padding-top: 12px;

                        .item {
                            margin-bottom: 0;
                            padding-bottom: 4px;

                            input[type="radio"],
                            input[type="checkbox"] {
                                width: 18px;
                                height: 18px;

                                &:before {
                                    width: 18px;
                                    height: 18px;
                                    -webkit-background-size: 17px;
                                    background-size: 17px;
                                }
                            }

                            a {

                                &:hover {
                                    color: $c-black2;
                                    opacity: .65;
                                }
                            }
                        }
                    }
                }

                @media screen and (max-width: $screen__l) {

                    &-title,
                    &-content {
                        padding-left: 32px;
                        padding-right: 32px;
                    }

                    &-title {
                        pointer-events: none;
                        padding-top: 40px;

                        &:after {
                            content: none;
                        }
                    }

                    &-content {
                        height: auto;
                        margin-bottom: 40px;

                        .items {
                            padding-top: 0;
                        }
                    }
                }
            }
        }

        .toolbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;

            .category-description {
                margin-bottom: 0;
            }

            .sorter-mobile {
                display: none;
            }

            .modes,
            .catalog-filters-mobile {
                display: none;
            }

            &:before,
            &:after {
                content: none;
            }

            &-amount {
                font-size: 14px;
                padding-top: 0;
            }

            &-number {
            }

            .sorter {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-image: url('../images/icons/arrow-down-black.svg');
                    background-repeat: no-repeat;
                    -webkit-background-size: 16px 12px;
                    background-size: 16px 12px;
                    width: 16px;
                    height: 12px;
                }

                .sorter-options {
                    -webkit-appearance: none;
                    border-radius: 4px;
                    padding-right: 25px;
                    border-color: $c-chromatic2;
                }

                .sorter-action {

                    &:before {
                        content: none;
                    }
                }
            }

            .pages {

                .items {

                    .item {
                        width: 36px;
                        height: 36px;
                        border-radius: 50%;
                        color: $c-black2;
                        border: 1px solid $c-chromatic2;
                        margin: 0 8px;
                        font-size: 14px;

                        > a,
                        span {
                            font-weight: 400;
                            font-family: $f-roboto;
                        }

                        > a {
                            color: $c-black2;
                        }

                        &.pages-item {
                            border-radius: 4px;
                            height: 36px;
                            width: 80px;
                            margin: 0 28px;

                            .action {
                                border: none;
                                margin: 0;
                                display: block;
                                width: 100%;
                            }
                        }

                        &.current {
                            background-color: $c-black2;
                            border: 1px solid $c-black2;

                            span {
                                color: $c-white;
                            }
                        }

                        &:not(.current) {
                            transition: background-color .24s ease-in-out, color .24s ease-in-out;

                            &:hover {
                                background-color: $c-chromatic2;
                                color: $c-white;
                            }
                        }

                        .action {

                            &:before {
                                content: none;
                            }

                            span:not(.label) {
                                clip: auto;
                                height: auto;
                                margin: 0;
                                overflow: visible;
                                position: static;
                                width: auto;
                            }
                        }
                    }
                }
            }
        }

        .products.wrapper ~ .toolbar {
            justify-content: center;

            .catalog-promo-message,
            .catalog-filters-mobile {
                display: none;
            }
        }

        .category-description {
            overflow: hidden;
            margin: 15px 0;
        }

        #readButton {
            background-color: $c-white;
            border: none;
            margin: 30px auto;

            .arrow-down,
            .arrow-up {
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 26px;
                    height: 26px;
                    background-image: url('../images/icons/arrow-down-black.svg');
                    background-size: 26px 26px;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
            }

            .arrow-up {
                &:before {
                    transform: translate(-50%, -50%) rotate(180deg);
                }
            }

            .arrow-down {
                &:before {
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .am_shopby_apply_filters {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 292px;
        transform: translate3d(-100%, 0%, 1px);
        transition: transform .24s ease-in-out;
        background-color: $c-white;
        z-index: 9998;

        .am-show-button {
            padding: 0;
            background-color: $c-black2;
            height: 64px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;

            .filter-actions {
                padding-right: 60px;
                margin-bottom: 0;

                .filter-clear {
                    font-size: 16px;
                    border-radius: 0;
                    padding: 0;

                    &:after {
                        content: none;
                    }
                }
            }

            .am-items {
                color: $c-white;
                font-size: 16px !important;
            }

            button {
                margin: 0;
                background-color: transparent;
                padding: 12px 4px !important;
                text-transform: uppercase;
                font-size: 16px !important;
            }
        }

        @media screen and (max-width: $screen__l) {
            visibility: visible;
            display: block;
        }
    }

    &.catalog-filter-mobile-opened .am_shopby_apply_filters {
        transform: translate3d(0%, 0%, 1px);
    }

    @media screen and (max-width: $screen__l) {

        .page-wrapper {
            position: relative;

            .columns {
                position: static;
            }

            .breadcrumbs {

            }

            .toolbar {
                flex-direction: row-reverse;
                flex-wrap: wrap;
                justify-content: space-between;

                .sorter {
                    display: none;
                }

                .catalog-promo-message {
                    width: 100%;
                    order: -1;
                    margin-bottom: 30px;
                }

                .catalog-filters-mobile {
                    display: block;
                    cursor: pointer;

                    span {

                        &:hover {
                            color: $c-chromatic7;
                        }
                    }
                }
            }

            .block.filter {
                position: fixed;
                left: 0;
                top: 0;
                height: 100vh;
                width: 292px;
                transform: translate3d(-100%, 0%, 1px);
                transition: transform .24s ease-in-out;
                background-color: $c-white;
                z-index: 9998;
            }
        }
    }
}

.catalog-filter-mobile-opened.page-products {
    height: 100vh;
    overflow: hidden;

    .page-wrapper {

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .75);
            z-index: 100;
        }

        .block.filter {
            transform: translate3d(0%, 0%, 1px);
            padding-bottom: 120px;
            overflow-y: scroll;
        }
    }
}

.products {
    margin: $indent__l 0 36px;
}

.product {
    &-items {
        @extend .abs-reset-list;
        display: flex;
        flex-wrap: wrap;

        .action.tocart {
            margin: 0;
        }

        .action.towishlist {
            position: absolute;
            right: 0;
            top: 0;
            display: block;

            &:before {
                content: '' !important;
                background-image: url('../images/icons/wishlist.svg');
                background-repeat: no-repeat;
                -webkit-background-size: 16px;
                background-size: 16px;
                width: 16px;
                height: 16px;
                vertical-align: baseline;
            }

            &:hover {

                &:before {
                    background-image: url('../images/icons/wishlist-black.svg');
                }
            }
        }
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        width: 24.75%;

        .products-grid & {
            display: inline-block;
            width: 49.75%;
        }

        .quickdrawer-container {
            display: block;
            position: relative;
            overflow: hidden;
            padding-top: 150%;
            background-color: $c-white2;
            background-image: url('../images/shan-grey.svg');
            background-repeat: no-repeat;
            background-position: center center;

            .owl-carousel {

                li,
                a {
                    display: block;
                    height: 100%;
                }
            }

            .owl-carousel.owl-notloaded {
                display: block !important;
            }

            .quickdrawer-addtocart {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 12;

                .size-guide-link {
                    display: none;
                }

                .product-item-actions {
                    margin: 0;
                    position: absolute;
                    bottom: -66px;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    transition: bottom .24s ease-in-out, opacity .24s ease-in-out;

                    .actions-primary {
                        display: block;
                        background-color: rgba(255, 255, 255, .7);
                        padding: 9px 8px;
                        height: 66px;

                        button {
                            width: 100%;
                            margin: 0 !important;

                            .text-addtocart {
                                display: block;
                            }

                            .text-choosesize {
                                display: none;
                                margin: 0;
                                color: $c-white;

                                &:hover {
                                    color: $c-white;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }

                .product-item-inner {

                    &:after {
                        content: '';
                        display: table;
                        clear: both;
                    }
                }

                [data-role*="swatch-option"] {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    transition: bottom .24s ease-in-out, opacity .24s ease-in-out;
                    opacity: 0;

                    .swatch-attribute {
                        margin-bottom: 0;
                    }

                    .swatch-attribute-options {
                        margin-top: 0;
                        background-color: rgba(255, 255, 255, .7);
                        justify-content: center;
                        padding: 16px;
                    }

                    .swatch-option {
                        position: relative;
                        flex: none;
                        padding: 0;
                        margin-right: 0;
                        margin-bottom: 0;
                        text-align: center;
                        border-radius: 0;
                        border: none;
                        background-color: transparent;
                        font-size: 13px;

                        &:hover {
                            background-color: transparent;
                        }

                        &.selected {
                            background-color: transparent;
                        }

                        &[data-child-product-stock="0"] {
                            color: $c-chromatic5;
                        }

                        &:hover,
                        &.selected {
                            color: $c-black2;

                            &:after {
                                position: absolute;
                                content: '';
                                display: block;
                                width: 24px;
                                height: 1px;
                                background-color: $c-black2;
                                bottom: -6px;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }

                    .swatch-option + .swatch-option {
                        margin-left: 26px;
                    }
                }

                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }

            &.inside,
            &.add-to-cart-pending {

                .product-item-actions {
                    bottom: 0;
                    opacity: 1;
                }

                &.choosesize {

                    [data-role*="swatch-option"] {
                        bottom: 66px;
                        opacity: 1;

                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 100%;
                            left: 0;
                            height: 24px;
                            width: 100%;
                        }
                    }
                }

                &.sizetext {

                    .product-item-actions {

                        button {

                            .text-addtocart {
                                display: none;
                            }

                            .text-choosesize {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        &-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            .owl-nav {
                display: none;
            }

            .owl-prev,
            .owl-next {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0 !important;

                span {
                    display: block;
                    padding: 16px;
                    font-size: 22px;
                    color: $c-black2;
                }

                &.disabled {
                    pointer-events: all !important;
                }
            }

            .owl-prev {
                left: 0;
            }

            .owl-next {
                right: 0;
            }

            &:hover {
                .owl-nav {
                    display: block;
                }
            }
        }

        &-details {
            position: relative;
            margin-top: 20px;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            margin: 0 0 $indent__xs;
            word-wrap: break-word;
            hyphens: auto;
        }

        &-link {

            &:hover {
                text-decoration: none !important;
            }
        }

        &-info {
            max-width: 100%;
            width: 100%;

            .page-products & {
                width: 100%;
            }
        }

        &-actions {
            display: none;

            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto-gridlist;

                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-photo {
            display: block;
        }

        &-description {
            margin: $indent__m 0;
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-box {
            margin: $indent__s 0 14px;
            display: flex;

            .price {
                font-size: 14px;
                white-space: nowrap;
            }

            .price-label {
                font-size: $font-size__s;

                &:after {
                    content: ':';
                }
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
                font-weight: $font-weight__bold;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;

            .price {
                color: $c-chromatic5;
                font-weight: 400;
            }
        }

        .old-price {
            order: -1;
            margin-right: 16px;

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }
    }

    &.special-tag {
        text-transform: uppercase;
        font-size: 11px;
        font-family: $f-roboto-bold;
    }
}

.column.main {
    .product {
        &-items {
            margin-left: -$indent__base;
        }

        &-item {
            padding-left: $indent__base;
            margin-bottom: 44px;
        }

        @media screen and (max-width: $screen__s) {

            &-items {
                margin-left: 0;
                justify-content: space-between;
            }

            &-item {
                padding-left: 0;
                padding-right: 0;
                width: 49%;
            }
        }
    }

}

.category-view {

    .category-misc {
        //display: none;
    }
}

.category-misc {
    border: 1px solid $c-chromatic3;
    padding: 100px;
    margin-top: 120px;

    &__columns {
        display: flex;
        justify-content: center;

        > div {

            &:first-of-type {
                width: 50%;
            }

            &:nth-child(even) {
                padding-top: 56px;
                width: 50%;
                margin-left: 10%;
            }
        }

        @media screen and (max-width: 1200px) {
            flex-direction: column;

            > div {

                &:first-of-type {
                    width: 100%;
                }

                &:nth-child(even) {
                    padding-top: 56px;
                    width: 100%;
                    margin-left: 0;
                }
            }
        }
    }

    p {
        margin-bottom: 20px;
    }

    a {
        display: inline-block;
        margin-top: 60px;
        text-decoration: underline;

        &:hover {
            color: $c-black2;
        }
    }

    h2 {
        width: 100%;
        font-size: 32px;
        margin-bottom: 24px;
    }

    .title {
        font-size: 20px;
    }

    ul,
    li {
        list-style-type: disc;
        font-size: 16px;
    }

    ul {
        padding-left: 20px;
    }
}

.price-container {
    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {
        &:before {
            content: '(' attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-photo {
                display: table-cell;
                padding: 0 $indent__l $indent__l 0;
                vertical-align: top;
                width: 1%;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

// Related products
.page-layout-1column .products-grid.products-related,
.page-layout-1column .products-grid.products-upsell {
    margin-bottom: 0;

    .product-items {
        justify-content: flex-start;
    }

    .product-item {
        width: 25%;
        margin-bottom: 0;
    }

    @media screen and (max-width: $screen__s) {
        .product-item {
            width: 100%;
            padding-left: 0;
        }

        .product-items {
            margin-left: 0;
        }

        .product-image-container {
            width: 100% !important;
        }
    }
}

.block.related,
.block.upsell {
    margin-top: 100px;
    width: 100%;

    .block-title {
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    @media screen and (max-width: $screen__l) {
        padding: 0 !important;
        margin-top: 0;

        .block-title {
            text-align: center;
        }
    }

    .owl-nav {
        width: 100%;
        position: absolute;
        top: 32%;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        pointer-events: none;
        transform: translateY(-50%);
    }

    .owl-next,
    .owl-prev {

        &:hover {
            background-color: transparent;
        }
    }

    .prev-arrow,
    .next-arrow {
        width: 42px;
        height: 42px;
        background-color: rgba($c-white, 0.8);
        display: block;
        position: relative;
        border-radius: 50%;
        pointer-events: all;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 26px;
            height: 26px;
            background-image: url('../images/icons/arrow-down-black.svg');
            background-size: 26px 26px;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    .prev-arrow {

        &:before {
            transform: translate(-50%, -50%) rotate(90deg);
        }
    }

    .next-arrow {

        &:before {
            transform: translate(-50%, -50%) rotate(-90deg);
        }
    }
}

.page-layout-1column .block.widget .products-grid .product-item {
    width: 16.6667% !important;
    margin-left: 0 !important;
    padding-left: 10px;
    margin-bottom: 0;

    @media screen and (max-width: $screen__m) {
        width: 33.333% !important;
    }

    @media screen and (max-width: $screen__s) {
        width: 50% !important;
    }
}

.block.block-viewed-products-grid {
    width: 100%;
    margin-top: 70px;

    .block-title {
        margin-bottom: 20px;
    }

    .block-title strong {
        font-size: $fz-h3;
    }

    .product-items {
        justify-content: flex-start;
    }

    @media screen and (max-width: $screen__m) {

        .block-title {
            text-align: center;

            strong {
                font-size: $fz-h3-sm;
            }
        }
    }
}

// Category landing page
// ----------------------------------------------
.category-landing {

    .block.filter,
    .sidebar.sidebar-main,
    .columns {
        display: none !important;
    }

    .category-cms {

        .category-title {
            margin-top: 50px;
            text-align: center;

            h1 {
                font-size: 3.6rem;
                position: relative;
                display: inline-block;
                margin-bottom: 0;
            }
        }

        .children {
            margin: 40px -15px 0;
            display: flex;
            text-align: center;
            justify-content: space-between;
            align-items: center;

            > div {
                padding: 0 15px;
            }

            a {
                display: block;
                padding: 110px 40px 70px 40px;
                background-color: $c-chromatic1;
            }

            p {
                margin-top: 30px;
                @extend .h4;
            }
        }
    }

    @media screen and (max-width: $screen__l) {

        .category-cms {

            .children {
                flex-direction: column;

                > div + div {
                    margin-top: 40px;
                }

                a {
                    padding: 40px 40px 60px 40px;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {
                display: block;

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;

                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .products-grid .product-item {
        width: 32.3333%;
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 33.3333%;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 50%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .products-grid {
        .product-item {
            width: 20%;
        }
    }

    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 16.6666%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                padding: 0;
                margin-left: 1%;
                width: 32.3333%;

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(2n + 1) {
                        margin-left: 0;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

@include min-screen($screen__ml) {
    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 23.233%) / 3);
                padding: 0;
                width: 23.233%;

                &:nth-child(3n + 1) {
                    margin-left: calc((100% - 4 * 23.233%) / 3);
                }

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
}
