
#backinstock_notification {
  padding-right: 10px;
  margin-top: 35px;

  button[type="submit"] {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.notifyme-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .95);
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  text-align: center;

  button {
    width: 100%;
    display: block;
    margin: 12px 0 20px;
  }

  &__content {
    background-color: $c-white;
    border: 1px solid $c-chromatic2;
    padding: 56px;
    max-width: 426px;
    border-radius: 2px;
    position: relative;

    #backinstock_response {
      margin-top: 20px;
      display: block;
      transition: opacity .24s ease-in-out;
      opacity: 1;
    }

    .loader {
      display: block;
      pointer-events: none;
      opacity: 0;
      transition: opacity .24s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(255,255,255,.9);

      .lds-ring {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 64px;
        height: 64px;
      }

      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid $c-black2;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $c-black2 transparent transparent transparent;
      }

      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }

      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }

      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }

      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

    }

    &.before-loading,
    &.loading {

      #backinstock_response {
        display: none;
        opacity: 0;
      }
    }

    &.loading {

      .loader {
        pointer-events: all;
        opacity: 1;
      }
    }
  }

  &__info {
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  &__terms {
    font-size: 10px;
    color: $c-chromatic5;
    margin-bottom: 0;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
  }

  &__form {

    ::placeholder {
      color: $c-chromatic5;
      font-size: 16px;
    }
  }

  &__close {
    width: 42px;
    height: 42px;
    margin-bottom: 30px;
    cursor: pointer;
  }
}

body.notifyme-modal--opened {
  height: 100vh;
  overflow: hidden;

  .notifyme-modal {
    display: flex;
  }
}
