// Override
header.page-header .header .navigation li.level0.has-active > a, header.page-header .header .navigation li.level0.active > a {
    opacity: 1 !important;
}
// Custom code

.header-promo {
    background-color: $c-white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;

    +.page-header {
        top: 34px;

        +.page-main {
            padding-top: 82px;

            @media screen and (min-width:$screen__l + 1) {
                padding-top: 100px;
            }
        }
    }
    &__container {
        position: relative;
        @extend .s_container--large;
        display: flex;
        justify-content: center;
        height: 34px;
        align-items: center;

        &:after {
            display: block;
            content: '';
            position: absolute;
            bottom: 0;
            height: 1px;
            max-width: 1800px;
            background-color: $c-chromatic2;
            left: 30px;
            right: 0px;
        }
        .catalog-category-view & {
            &:after {
                left: 0;
            }
        }
        .mobile {
            display: none;
        }
        @media screen and (max-width:$screen__s) {
            &:after {
                left: 15px;
                right: 15px;
            }
            .catalog-category-view & {
                &:after {
                    right: 0;
                }
            }
            .mobile {
                display: block;
            }
        }
    }
    p, a {
        font-size: 12px;
    }
    p {
        color: $c-black;
        text-align: center;

        @media screen and (max-width:$screen__m) {
            max-width: 450px;
        }
        a {
            display: inline-block;
            text-decoration: underline;
            margin-left: 16px;
        }
    }
}
@media screen and (max-width:$screen__l) {
    .navigation-mobile-open .page-header {
        z-index: 20;
    }
}
header.page-header {
    background-color: $c-white;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;    /*height: 48px;*/
    z-index: 10;

    .navigation-hover & {
        box-shadow: none;
    }
    .header {
        position: relative;

        li {
            font-size: 14px;
        }
        &.content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 16px 260px 0;
            @extend .s_container--large;

            &:before, &:after {
                content: none;
            }
        }
        &.misc {
            display: flex;
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);

            .block-search {
                display: none;
                position: relative;
                padding-left: 0;
                padding-right: 20px;
                width: auto;
                align-items: center;

                &.visible {
                    display: flex;
                    order: 2;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: -.5px;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background-color: $c-chromatic11;
                }
            }
            .field.search {
                cursor: pointer;

                .label {
                    height: auto;
                    margin: 0;
                    width: auto;
                    clip: auto;
                    position: relative;
                    cursor: pointer;
                    text-transform: uppercase;

                    &:before {
                        content: '';
                        background-image: url('../images/icons/search-black.svg');
                        background-repeat: no-repeat;
                        width: 16px;
                        height: 16px;
                        background-size: cover;
                        margin-right: 4px;
                    }
                    > span {
                        height: auto;
                        margin: 0;
                        width: auto;
                        clip: auto;
                        position: static;
                        color: $c-white;
                    }
                    &:hover {
                        span {
                            opacity: .65;
                        }
                    }
                }
                .control {
                    position: absolute;
                    left: 22px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0;
                    padding: 0;
                    background-color: $c-white;
                    z-index: 1;

                    .close-search {
                        position: absolute;
                        left: -7px;
                        top: 50%;
                        transform: translate(-100%, -50%);
                        display: none;
                    }
                }
                .action.advanced {
                    display: none;
                }
                .input-text {
                    background-color: transparent;
                    border: none;
                    color: $c-black;
                    padding: 0;
                    max-width: 170px;

                    &:focus {
                        border: none;
                        box-shadow: none;
                    }
                }
            }
            .minisearch {
                display: flex;
                flex-direction: row-reverse;

                &.active {
                    .control {
                        width: 320px;

                        .close-search {
                            display: block;
                        }
                    }
                    .action.search {
                        visibility: visible;
                    }
                    .label:before {
                        visibility: hidden;
                    }
                    .actions {
                        visibility: visible;
                        pointer-events: all;
                    }
                }
                .actions {
                    visibility: hidden;
                    pointer-events: none;
                }
            }
            .actions {
                position: absolute;
                z-index: 100;
                top: 50%;
                right: -190px;
                left: auto;
                cursor: pointer;
                transform: translateY(-50%);

                &.switcher-options {
                    position: static;
                    transform: none;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;

                    .action.toggle {
                        color: $c_black;
                        text-transform: uppercase;

                        .separator {
                            display: inline-block;

                            &:after {
                                content: '';
                                background: rgba(0, 0, 0, 0.3);
                                height: 10px;
                                width: 1px;
                                margin: 0 1px 0 4px;
                                display: inline-block;
                            }
                        }
                    }
                }
            }
            .action.search {
                position: static;
                padding: 10px !important;

                &:before {
                    content: '';
                    background-image: url('../images/icons/search-black.svg');
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                    background-size: cover;
                }
            }
            .header.links > .authorization-link {
                margin-bottom: 0;
                position: relative;
                padding-right: 20px;
                padding-left: 20px;

                a {
                    color: $c-black;
                    text-transform: uppercase;
                    font-family: $f-roboto;

                    &:hover {
                        text-decoration: none;
                        opacity: .65;
                    }
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: -.5px;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background-color: $c-chromatic11;
                }
            }
            .minicart-wrapper {
                margin-left: 20px;
                display: flex;
                align-items: center;
                order: 4;

                &:hover {
                    &:before {
                        opacity: .65;
                    }
                }
            }
            .customer-welcome {
                color: $c-black;
                padding: 15px 20px;

                + .authorization-link {
                    display: none;
                }
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: -.5px;
                    transform: translateY(-50%);
                    height: 16px;
                    width: 1px;
                    background-color: $c-chromatic11;
                }
                .customer-name {
                    text-transform: uppercase;
                }
                .customer-menu {
                    display: none;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 100%);
                    background-color: $c-white;
                    border: 1px solid $c-white2;
                    box-shadow: 0px 5px 5px -1px rgba($c-black2, 0.5);
                    white-space: nowrap;
                    padding: 20px 30px;

                    li {
                        margin-bottom: 20px;
                        text-align: center;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                    a:hover {
                        color: $c-black;
                        text-decoration: underline;
                    }
                    a:visited, a:focus, a:active {
                        color: $c-black;
                    }
                }
                &:hover {
                    .customer-menu {
                        display: block;
                    }
                }
            }
        }
        &.links {
            display: flex;
            align-items: center;
            order: 2;
        }
        &-logo {
            float: left;
            margin: 0;
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);

            @media screen and (min-width:$screen__l + 1) {
                left: 50%;
                top: 25%;
                transform: translate(-50%, -50%);
            }

            a {
                display: block;
                height: 19px;
                width: 185px;
            }
        }
        .navigation-mobile-menu {
            display: none;
        }
        .navigation-mobile-close {
            display: none;
        }
        .navigation {
            background-color: initial;
            position: static;
            width: auto;
            top: auto;
            margin: 0 auto;
            font-weight: normal;

            @media screen and (min-width:$screen__l + 1) {
                margin: 35px auto 0;
            }

            > ul {
                position: static;
                display: flex;
            }
            li.level0.has-active, li.level0.active {
                > a {
                    opacity: .65;
                    border: none;
                }
            }
            @media screen and (min-width:$screen__l + 1) {
                li.level0.parent {
                    > a {
                        position: relative;

                        &:after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 8px;
                            transform-origin: top;
                            transform: translateY(-50%);
                            background-image: url('../images/icons/arrow-down-black.svg');
                            background-repeat: no-repeat;
                            background-size: 10px 10px;
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }
            @media screen and (max-width:$screen__l) {
                li.level0.parent {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 8px;
                        transform-origin: top;
                        transform: translateY(-50%);
                        background-image: url('../images/icons/arrow-down-black.svg');
                        background-repeat: no-repeat;
                        background-size: 10px 10px;
                        width: 10px;
                        height: 10px;
                    }
                }
            }
            li.level0, li.navigation-static-link {
                margin: 0;
                position: static;
                font-size: 14px;

                > a {
                    padding: 0 20px 16px;
                    line-height: 1.2;
                    color: $c-black;
                    display: block;
                    text-transform: uppercase;
                    font-family: $f-roboto;

                    @media screen and (max-width:$screen__l) {
                        padding: 16px;
                        display: inline-block;
                        width: 80%;
                        font-size: 16px;
                        font-weight: 400;

                        &:after {
                            content: unset;
                        }
                    }

                    &:hover {
                        text-decoration: none;
                        color: rgba(0, 0, 0, .65);
                    }
                }
                ul.menu-images {
                    li.level1 {
                        display: flex;
                        flex-direction: column-reverse;
                        align-items: center;
                        margin-bottom: 48px;

                        > a {
                            margin-bottom: 0;
                            font-size: 14px;

                            img {
                                max-width: 546px;
                                margin-bottom: 14px;
                            }
                        }
                    }
                    @media screen and (max-width:$screen__l) {
                        li.level1 {
                            align-items: flex-start;
                            margin-bottom: 24px;

                            > a {
                                img {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .level0.submenu > ul {
                    padding-right: 30px;
                }
                @media screen and (min-width:$screen__l + 1) {
                    &.parent:hover {
                        > a {
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                width: 150vw;
                                height: 460px;
                                top: 100%;
                                left: 50%;
                                right: 0;
                                transform: translateX(-50%);
                                box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19);
                                background-color: $c-white;
                            }
                        }
                        ul.level0 {
                            display: flex;
                            justify-content: center;
                            padding-top: 36px;
                            padding-right: 30px;
                            padding-left: 30px;
                            width: 100%;
                            height: 460px;
                            box-shadow: none;
                            border: none;
                            color: $c-black2;
                        }
                    }
                }
            }
            li.level1, li.level2 {
                > a {
                    display: block;
                    padding: 0;
                    color: $c-black2;
                    font-family: $f-roboto;
                }
                > a:hover {
                    background-color: transparent;
                }
            }
            li.level1 {
                font-weight: 400;

                &:not(.cms-nav-item) {
                    flex-shrink: 0;
                }
                &.cms-nav-item.square {
                    max-width: 343px;
                }
                &.cms-nav-item.rectangle {
                    max-width: 548px;
                }
                img {
                    display: block;
                }
                p {
                    text-align: center;
                    margin-top: 10px;
                }
                > a {
                    font-size: 18px;
                    margin-bottom: 24px;
                }
            }
            li.level1 + li.level1 {
                margin-left: 6%;
            }
            li.level2 {
                font-size: 14px;
                font-weight: 400;

                > a {
                    margin-bottom: 20px;
                }
            }
            ul.level1, ul.level2 {
                display: block;
                position: static;
                border: none;
                box-shadow: none;
                min-width: 0;
            }
        }
        .action.showcart {
            height: 17px;

            &:before {
                content: '';
                background-image: url('../images/icons/cart-black.svg');
                background-repeat: no-repeat;
                width: 15px;
                height: 17px;
                background-size: cover;
            }
            &:hover {
                opacity: .65;
            }
            .counter.qty {
                background: transparent;
                height: auto;
                line-height: 1;
                border-radius: 0;
                min-width: 0;
                margin: 0 0 0 5px;
                display: inline;
                padding: 0;
                white-space: nowrap;
            }
            .counter-number {
                display: none;
            }
            .counter-label {
                height: auto;
                clip: unset;
                width: auto;
                position: static;
            }
            .loader {
                display: none !important;
            }
        }
    }
    .navigation .level0 .submenu .active > a {
        border: none;
        border-width: 0;
        font-family: $f-roboto-bold;
    }
    @media screen and (max-width:$screen__l) {
        display: flex;
        flex-direction: column-reverse;

        .header.content {
            order: -1;
            width: 100%;
            height: 48px;
            justify-content: center;
            padding: 0;

            .navigation-mobile-menu {
                display: flex;
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
                align-items: center;
                cursor: pointer;
                width: 20px;
                height: 20px;

                .navigation-mobile-open & {
                    display: none;
                }
            }
            .navigation-mobile-close {
                display: none;
                position: absolute;
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
                align-items: center;
                cursor: pointer;
                width: 20px;
                height: 20px;

                .navigation-mobile-open & {
                    display: flex;
                }
            }
            .header-logo {
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
            .header.misc {
                right: 16px;
            }
            .customer-welcome {
                .customer-name {
                    display: none;
                }
                + .authorization-link {
                    display: none;
                }
                .customer-links {
                    padding: 0;
                }
                li {
                    position: relative;
                }
            }
            .navigation {
                position: absolute;
                top: 47px;
                left: 0;
                width: 100%;
                height: 100vh;
                padding-top: 10px;
                background-color: $c-white;
                justify-content: flex-start;
                flex-direction: column;
                pointer-events: auto;
                padding-left: 0;
                padding-right: 0;
                transition: transform .48s ease-in-out;
                transform: translateX(-100%);
                overflow-y: scroll;
                padding-bottom: 120px;

                .navigation-mobile-open & {
                    transform: translateX(0%);
                }
                .block.block-search {
                    float: none;
                    width: 100%;
                    padding-left: 16px;
                    padding-right: 16px;

                    .close-search, .nested {
                        display: none;
                    }
                    .control {
                        border-top: none;
                    }
                    ::placeholder {
                        color: rgba($c-black, 0.8);
                    }
                    input {
                        background-color: $c-chromatic1;
                        border-color: transparent;
                        color: $c-black;

                        &:focus {
                            border-color: $c-white2;
                        }
                    }
                    .minisearch {
                        position: relative;
                    }
                    .action.search {
                        padding: 11px !important;
                        right: 0;
                    }
                }
                > ul {
                    flex-direction: column;
                }
                .header.links {
                    position: relative;
                    flex-direction: column;
                    align-items: stretch;

                    .authorization-link {
                        position: relative;

                        a {
                            position: relative;
                            display: block;

                            &:hover {
                                opacity: .65;
                            }
                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                right: 16px;
                                top: 50%;
                                transform: translateY(-50%) rotate(-90deg);
                                background-image: url('../images/icons/arrow-down-black.svg');
                                background-size: 26px 26px;
                                background-repeat: no-repeat;
                                width: 26px;
                                height: 26px;
                            }
                        }
                    }
                    li:first-of-type {
                        margin-top: 20px;
                        padding-top: 20px;

                        &:before {
                            content: '';
                            width: 80px;
                            height: 1px;
                            background-color: $c-chromatic7;
                            position: absolute;
                            top: 0;
                            left: 16px;
                        }
                    }
                }
                .level0 {
                    border: none;
                }
                li.level0.parent {
                    &.navigation-mobile-touch {
                        @media screen and (max-width:$screen__l) {
                            &:before {
                                transform: rotate(0deg) translateY(-50%);
                                background-image: url('../images/icons/minus-black.svg');
                                transform-origin: initial;
                                right: 24px;
                            }
                        }
                        ul.level0.submenu {
                            height: auto;
                            padding-top: 4px;
                            padding-left: 32px;
                            padding-bottom: 10px;

                            @media screen and (max-width:$screen__l) {
                                margin-top: 15px;
                            }
                        }

                        @media screen and (min-width:$screen__l + 1) {
                            a {
                                &:after {
                                    transform: rotate(0deg) translateY(-50%);
                                    background-image: url('../images/icons/minus.svg');
                                    transform-origin: initial;
                                    right: 24px;
                                }
                            }
                        }
                    }
                }
                li.level0.has-active, li.level0.active {
                    > a {
                        opacity: 1;
                    }
                }
                @media screen and (min-width:$screen__l + 1) {
                    li.level0 {
                        > a {
                            display: block;
                            padding: 16px;
                            font-size: 16px;
                            text-transform: none;
                            font-family: $f-roboto;
                            font-weight: 400;
                            color: $c-white;

                            &:after {
                                background-image: url('../images/icons/close.svg');
                                transform: translateY(-50%) rotate(45deg);
                                right: 16px;
                                top: 50%;
                                width: 20px;
                                height: 20px;
                                -webkit-background-size: 20px 20px;
                                background-size: 20px 20px;
                            }
                        }
                    }
                }
                @media screen and (max-width:$screen__l) {
                    li.level0 {
                        display: block;
                        font-size: 16px;
                        text-transform: none;
                        font-family: $f-roboto;
                        font-weight: 400;
                        color: $c-white;

                        &:before {
                            background-image: url('../images/icons/close-black.svg');
                            transform: translateY(-50%) rotate(45deg);
                            right: 16px;
                            top: 26px;
                            width: 20px;
                            height: 20px;
                            -webkit-background-size: 20px 20px;
                            background-size: 20px 20px;
                        }
                    }
                }
                li.navigation-static-link, .header.links li {
                    > a {
                        display: block;
                        padding: 16px;
                        font-size: 16px;
                        text-transform: none;
                        font-family: $f-roboto;
                        font-weight: 400;
                        color: $c-black;

                        &:after {
                            background-image: url('../images/icons/close.svg');
                            transform: translateY(-50%) rotate(45deg);
                            right: 16px;
                            top: 50%;
                            width: 20px;
                            height: 20px;
                            -webkit-background-size: 20px 20px;
                            background-size: 20px 20px;
                        }
                    }
                }
                li.level0 + li.navigation-static-link {
                    position: relative;
                    margin-top: 20px;
                    padding-top: 20px;

                    &:before {
                        content: '';
                        width: 80px;
                        height: 1px;
                        background-color: $c-chromatic7;
                        position: absolute;
                        top: 0;
                        left: 16px;
                    }
                }
                ul.level0.submenu {
                    height: 0;
                }
                li.level1 {
                    > a {
                        color: rgba(0, 0, 0, .75);
                        font-size: 14px;
                    }
                    &.cms-nav-item {
                        display: none;
                    }
                }
                li.level1 + li.level1 {
                    margin-left: 0;
                }
                li.level2 {
                    > a {
                        color: $c-black;
                        font-size: 14px;
                    }
                }
                .switcher-language {
                    padding: 16px 24px;
                    font-size: 16px;

                    .switcher-label {
                        width: auto;
                        height: auto;
                        position: static;
                        margin-right: 10px;
                        color: $c-black;

                        span:after {
                            content: ':';
                            display: inline-block;
                            padding-left: 4px;
                        }
                    }
                    .actions.switcher-options {
                        text-transform: uppercase;

                        .action.toggle {
                            color: $c_black;
                            text-transform: uppercase;

                            .separator {
                                display: inline-block;

                                &:after {
                                    content: '';
                                    background: rgba(0, 0, 0, 0.3);
                                    height: 10px;
                                    width: 1px;
                                    margin: 0 1px 0 4px;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                    &:after {
                        display: none;
                    }
                }
            }
            .header-links-wrapper {
                z-index: 20;
                left: 0;
                right: auto;
                top: 320px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 24px;
                transition: transform .48s ease-in-out;
                transform: translateX(-100vw);

                .navigation-mobile-open & {
                    transform: translateX(0%);
                }
            }
            .minicart-wrapper {
                .block-minicart {
                    top: 48px;
                }
                & {
                    &.active {
                        &:after {
                            bottom: -20px;
                        }
                    }
                }
            }
        }
        .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon), .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
            margin-left: 0;
        }
        .navigation .submenu:not(:first-child) .active > a {
            border-width: 0;
        }
        .header .navigation li.level0 > a:hover, .header .navigation li.navigation-static-link > a:hover {
            opacity: 1;
        }
        .header .navigation li.level1.last:not(.parent) > a, .header .navigation li.level1.last .level2.last > a {
            margin-bottom: 0;
        }
    }
    @media screen and (max-width:$screen__m) {
        .header.content .navigation {
            .switcher-language {
                padding: 16px;
            }
        }
    }
    @media screen and (max-width:$screen__s) {
        .minicart-wrapper {
            margin-top: 4px;
        }
        .header.misc .language, .header.misc .links {
            display: none !important;
        }
    }
}
