$form-minisearch_main_color: #121215;
$form-minisearch_additional_color: #e1e1e1;

.form.minisearch {
    .search-autocomplete {
        margin-top: 1px;

        @media screen and (max-width: $screen__m) {
            margin-top: -15px;
        }
        
        ul {
            li {
                border-left: 1px solid $form-minisearch_main_color;
                border-right: 1px solid $form-minisearch_main_color;
                padding: 8px 40px 8px 10px;
                
                &:not(:last-child) {
                    border-bottom: solid 1px $form-minisearch_additional_color;
                    border-top: 0;
                }
                
                &:first-child {
                    border-top: 1px solid $form-minisearch_main_color;
                }
                
                &:last-child {
                    border-bottom: 1px solid $form-minisearch_main_color;
                    border-top: 0;
                }
                
                span {
                    color: $form-minisearch_main_color;
                    
                    &.amount {
                        top: 8px;
                        right: 10px;
                        
                        @media screen and (max-width: $screen__m) {
                            right: 15px;
                        }
                        
                    }
                }
                
                &:hover,
                &.selected {
                    background: $form-minisearch_main_color;
                    border-top: 1px solid $form-minisearch_main_color;
                    border-bottom: 1px solid $form-minisearch_main_color;
                    
                    span {
                        color: $c-white;
                    }
                }
            }
        }
    }
}