
#store-locator-modal {

    .modal__wrapper {
        max-width: 1130px;
        background-color: transparent;
        box-shadow: none;
        top: 50px;
        transform: translateX(-50%)
    }

    .modal__back {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 40px;
        height: 40px;
        z-index: 100;
        border: 2px solid $c-black2;
        border-radius: 100%;
        display: none;

        &.visible {
            display: block;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        svg #back-fill {
            fill: $c-black2;
        }
    }

    .modal__close {
        position: fixed;
    }

    @media screen and (max-width: $screen__m) {

        .modal__wrapper {
            transform: translateX(0);
        }
    }
}

.locator {

    &__regions {

        &.hidden {
            display: none;
        }
    }

    &__title {
        display: flex;
        margin-bottom: 30px;
        font-size: 3.6rem;

        .text {
            padding-right: 20px;
        }

        .line {
            flex-basis: 100%;
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: 1px;
                width: 100%;
                background-color: $c-chromatic3;
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__store {
        width: 33%;
        max-width: 500px;
        padding-right: 20px;
        margin-bottom: 50px;

        &__title {
            font-size: 1.8rem;
            font-family: $f-roboto-medium;
            text-align: left;
            margin-bottom: 8px;
        }

        button {
            width: 100%;
        }
    }

    .location {
        font-family: $f-roboto-medium;
        font-size: 1.8rem;
        margin-bottom: 15px;
    }

    address {
        color: $c-black2;
        font-size: 1.6rem;
        text-align: left;

        span {
            display: inline;
        }

        a {
            font-size: 1.6rem;
        }
    }

    .hours {
        margin-top: 20px;
        font-size: 1.6rem;

        span {
            font-family: $f-roboto-medium;
        }

        &.unavailable {

            span {
                color: $c-chromatic5;
            }
        }
    }

    .links {
        margin-top: 30px;

        a {
            color: $c-chromatic5;
            font-size: 1.4rem;
            text-decoration: underline;
            margin-right: 5px;
        }
    }

    .schedule {
        width: 100%;
        max-width: 340px;

        dd {
            display: flex;
            justify-content: space-between;
        }

        .open-hours {
            text-align: right;
        }
    }
}

.locator__single-store {
    max-width: 640px;
    margin: 0 auto;
    display: none;

    &.opened {
        display: block;
    }

    address {
        white-space: normal;
        display: flex;
        justify-content: space-between;

        a {
            display: block;
            text-align: right;
        }
    }

    .links {
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

.locator--cms {
    margin-top: 45px;

    > h1 {
        text-align: center;
        margin-bottom: 30px;
    }
}

.locator__single-page {

    &__title {
        text-align: center;

        .sub {
            text-transform: uppercase;
        }
    }

    &__banner {
        display: flex;

        img {
            display: block;
        }

        .alpha {
            flex-basis: 897px;
            margin-right: 20px;
        }

        .beta {
            flex-basis: 322px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &__details {
        background-color: $c-white2;
        margin-top: 70px;
        padding: 70px 0;

        .content {
            display: flex;
            justify-content: space-between;

            .alpha {
                padding-right: 30px;
                flex-basis: 65%;
            }

            .beta {
                flex-basis: 35%;
            }
        }

        p {
            margin: 20px 0;
        }

        a[href^="tel"] {
            display: inline-block;
            font-family: $f-roboto-medium;
            margin-top: 20px;
        }

        dt {
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: $screen__l) {


    .locator {

        &__store {
            width: 50%;
        }
    }
}

@media screen and (max-width: $screen__m) {

    .locator {
        max-width: 400px;
        margin: 70px auto 0;

        &__store {
            width: 100%;
        }

        &__title {
            font-size: 2.4rem;

            .line {
                display: none;
            }
        }
    }

    .locator--cms {
        max-width: none;
    }

    .locator__single-page {

        &__banner {
            flex-direction: column;
            margin-top: 30px;
            padding-right: 0;
            padding-left: 0;

            .alpha {
                flex-basis: 100%;
                margin-right: 0;
            }

            .beta {
                margin-top: 20px;
                flex-direction: row;
                flex-basis: 100%;

                div:first-of-type {
                    margin-right: 20px;
                }
            }
        }

        &__details {

            .content {
                flex-direction: column;

                .alpha {
                    flex-basis: 100%;
                }

                .beta {
                    flex-basis: 100%;
                }
            }
        }
    }
}
