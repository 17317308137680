// Overrides
.product-info-main .box-tocart, .product-options-bottom .box-tocart {
    position: relative;
}
.product-info-main .box-tocart .field.qty, .product-options-bottom .box-tocart .field.qty {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}
.product-info-main {
    float: none !important;
}
.product.media {
    float: none !important;
}
.product-info-main .price-box .price-wrapper .price, .product-options-bottom .price-box .price-wrapper .price {
    font-size: 1.6rem;
    font-weight: normal;
}
.product-info-main .box-tocart, .product-options-bottom .box-tocart {
    margin: 0;
}
.product-info-main .box-tocart .actions, .product-options-bottom .box-tocart .actions {
    padding-top: 0;
}
// Custom styles
.catalog-product-view {
    .main {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .product-info-main {
        margin-top: 50px;
        width: 40%;
        max-width: 420px;
        padding-right: 30px;
    }
    .product.media {
        order: -1;
        width: calc(60% - 100px);
        max-width: 900px;
        margin-top: 50px;
        padding-left: 30px;
        margin-right: 100px;
    }
    .product.info.detailed {
        margin-bottom: 120px;
    }
    .product-mobile-header {
        display: none;
        order: -1;
        padding: 20px 15px;
    }
    @media screen and (max-width:$screen__l) {
        .product.media {
            margin-right: 50px;
        }
        .product.info.detailed {
            margin-bottom: 40px;
        }
    }
    @media screen and (max-width:$screen__m) {
        .product-info-main {
            width: 100%;
            max-width: none;
            padding: 0 30px;
            margin-top: 0;

            .product-info-price {
                display: none;
            }
        }
        .product.media {
            width: 100%;
            max-width: none;
            margin-right: 0;
            margin-top: 0;
            padding: 0;
        }
        .product-mobile-header {
            display: flex;
            justify-content: space-between;

            .price-container .price {
                font-size: 1.6rem;
            }
        }
    }
    .swatch-attribute.size {
        &.grid-1 {
            .swatch-attribute-options {
                grid-template-columns: 1fr;
            }
        }
        &.grid-2 {
            .swatch-attribute-options {
                grid-template-columns: 1fr 1fr;
            }
        }
        &.grid-3 {
            .swatch-attribute-options {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
        &.grid-4 {
            .swatch-attribute-options {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
        .swatch-attribute-options {
            display: grid;
        }
    }
}
.s_product-gallery {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &__media {
        width: 50%;
        padding: 6px;
    }
    &__placeholder {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: #f9f9f9;
        background-image: url(../images/shan-grey.svg);
        background-repeat: no-repeat;
        background-position: center center;
    }
    @media screen and (max-width:$screen__m) {
        &__media {
            width: 100%;
            padding: 0;
        }
        .owl-item {
            border-right: 3px solid $c-white;
            border-left: 3px solid $c-white;
        }
    }
}
.product-info-main {
    .product-options-bottom {
        display: flex;
    }
    .box-tocart {
        flex-basis: 100%;
        margin-top: 35px;

        .actions {
            display: block;

            button {
                width: 100%;
            }
            .action.primary {
                margin: 0;
                background-color: $c-black2;
                font-size: 1.6rem;
                font-weight: normal;
                border: none;
                border-radius: 0;
                transition: background-color 0.24s ease;

                &:hover {
                    background-color: $c-chromatic7;
                }
            }
        }
    }
    .action.towishlist {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        margin-left: 3px;
        margin-right: 8px;
        margin-top: 35px;
        border: none;
        border-radius: 0;
        color: $c-white;
        background-color: $c-black2;
        transition: background-color 0.24s ease;

        &:hover {
            background-color: $c-chromatic7;
            border: none;
        }
        svg {
            width: 18px;
            display: inline;
        }
        span {
            display: none;
        }
    }
    .product-addto-links, .product-options-bottom .product-addto-links {
        margin: 0;
    }
    .product-social-links {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;

        a {
            color: $c-black2;
            font-family: $f-roboto;
        }
        .share, .store-locator {
            position: relative;
            padding-left: 23px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                background-position: center center;
                background-size: 16px 16px;
            }
            &:hover {
                text-decoration: underline;
            }
        }
        .share {
            &:before {
                background-image: url('../images/icons/share.svg');
            }
        }
        .store-locator {
            margin-right: 8px;

            &:before {
                background-image: url('../images/icons/locator-pin.svg');
            }
        }
    }
    .product.attribute.overview {
        [itemprop="description"] {
            margin-top: 40px;

            p {
                margin-bottom: 20px;
            }
            ul, li {
                list-style-type: disc;
            }
            ul {
                margin: 20px 0;
                padding-left: 17px;
            }
            li {
                font-size: 1.6rem;
                margin-bottom: 0;
            }
            a {
                font-family: $f-roboto-medium;
            }
        }
    }
    @media screen and (max-width:$screen__m) {
        .product-options-bottom {
            flex-direction: column;
            padding-right: 10px;
        }
        .action.store-locator {
            text-align: center;
            margin-top: 10px;
            width: 100%;
        }
        .action.towishlist {
            width: auto;
            background: transparent;
            color: $c-black2;
            padding: 0;
            margin-top: 0;
            font-weight: normal;

            svg {
                margin-right: 5px;

                path {
                    fill: $c-black2 !important;
                }
            }
            span {
                display: inline-block;
                font-family: $f-roboto;
                font-size: 1.3rem;
            }
        }
    }
    @media screen and (max-width:$screen__s) {
        .action.towishlist {
            margin: 20px 0px 10px 0 !important;
        }
    }
}
.page-product-amgiftcard, .amcard-page-product {
    .column.main {
        position: relative;
        margin-top: 100px;

        .giftcard-switcher {
            position: absolute;
            top: -100px;
        }
    }
    .product.attribute.overview {
        .anchor {
            display: none !important;
        }
    }
    .giftcard-switcher {
        @extend .s_container--small;
        width: 100%;
        order: -2;
        text-align: center;
        margin-top: 40px;

        &-links {
            border-radius: 6px;
            overflow: hidden;
            display: inline-block;
            font-size: 0;
        }
        .block-product-link-inline.widget {
            display: inline-block;

            a {
                display: inline-block;
                color: $c-chromatic4;
                width: 165px;
                line-height: 35px;
                background: $c-white2;
                font-size: 1.4rem;
            }
        }
    }
    &.product-gift-card {
        .giftcard-switcher {
            .block-product-link-inline.widget:first-of-type {
                a {
                    background: $c-black;
                    color: $c-white;
                }
            }
        }
        .field.images, .field.sender-name, .field.message, .field.show-form {
            display: none;
        }
    }
    &.gift-card-type-virtual {
        .column.main {
            .giftcard-switcher {
                .block-product-link-inline.widget:last-of-type {
                    a {
                        background: $c-black;
                        color: $c-white;
                    }
                }
            }
            .product-info-main {
                margin-top: 30px;

                .product-info-pannel {
                    .product-info-section:not(:first-child) {
                        display: none;
                    }
                }
            }
        }
    }
    &.gift-card-type-traditional {
        .giftcard-switcher {
            .block-product-link-inline.widget:first-of-type {
                a {
                    background: $c-black;
                    color: $c-white;
                }
            }
        }
    }
    .s_product-gallery {
        justify-content: center;

        &__media {
            width: 75%;
        }
    }
    #am_giftcard_amount {
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        position: absolute;
    }
    #am_giftcard_message {
        min-height: 130px;
    }
    .amgiftcard-image {
        display: inline-block;
        width: 78px;
    }
    .amgiftcard-image.-selected {
        border-color: $c-notif !important;
    }
    .amgiftcard-image:hover {
        border-color: $c-black2;
    }
    .gift-card-fieldset {
        .field {
            padding-right: 10px;
        }
    }
    .field.amount {
        margin-right: -8px;
    }
    .field.message {
        color: $c-black;
    }
    .field.show-form {
        margin-bottom: 0;
    }
    .field.images {
        margin-top: 20px;
    }
    .amgiftcard-preview {
        display: none;
    }
    .box-tocart {
        width: 100%;
        margin-top: 10px;
    }
    .action.store-locator, .product-social-links, #backinstock_notification {
        display: none !important;
    }
    .product-info-main .product-options-bottom {
        display: block;

        .amcard-general-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;

            .amcard-form-container {
                order: 2;

                .amcard-price-container {
                    margin-top: 5px;
                    display: flex;
                    flex-wrap: wrap;

                    .amcard-label-block.-price {
                        flex: 1 1 calc(25% - 8px);
                        height: auto;
                        width: auto;
                        padding: 12px;
                        margin-right: 8px;
                        margin-bottom: 8px;
                        text-align: center;
                        border-radius: 3px;
                        transition: border-color 0.24s ease, background-color 0.24s ease, color 0.24s ease;
                        background-color: #FFF;
                        color: #121215;
                        border: 1px solid #d9d9d9;

                        &.-active {
                            border-color: #121215;
                            background-color: #121215;
                            color: #FFF;
                        }
                        input {
                            display: none;
                        }
                    }
                }
                .amcard-title {
                    font-weight: bold;

                    &.required {
                        &:after {
                            content: "*";
                            padding-left: 5px;
                            color: #e02b27;
                        }
                    }
                }
                .amcard-input.-hidden {
                    position: absolute;
                    display: inline;
                    width: 0;
                    height: 0;
                    opacity: 0;
                }
                .amcard-field-block {
                    margin: 5px 0 15px 0;
                }
            }
            .amcard-form-container.-interior {
                order: 1;

                .amcard-carousel-container {
                    .slick-slider {
                        padding: 0;

                        .slick-arrow.slick-next, .slick-arrow.slick-prev {
                            margin: 0;
                            padding: 0 !important;
                        }
                        .slick-arrow.slick-next {
                            right: -25px;
                        }
                        .slick-arrow.slick-prev {
                            left: -25px;
                        }
                    }
                    .amcard-image-container {
                        .amcard-image.-active {
                            border: solid 2px #6495ED;
                        }
                    }
                }
            }
            .amcard-preview-container {
                display: none;
            }
        }
    }
}
.s_p-details {
    margin-top: 10px;
    background-color: $c-white2;
    width: 100%;

    &__container {
        display: flex;
    }
    &__column {
        width: 50%;
        max-width: 700px;

        &__text {
            padding: 60px 80px;
            background-color: $c-white;

            .h3 {
                margin-bottom: 25px;
            }
            .h5 {
                margin-bottom: 20px;
                font-size: 2.2rem;
            }
            ul, li {
                list-style-type: disc;
            }
            ul {
                margin: 10px 0;
                padding-left: 17px;
            }
            li {
                font-size: 1.6rem;
                margin-bottom: 0;
            }
        }
        &__image {
            img {
                display: block;
            }
        }
    }
    &__column--alpha {
        @extend .s_p-details__column;
        margin-right: 60px;
        position: relative;
        top: 60px;
        align-self: flex-end;

        .s_p-details__column__text {
            margin-bottom: 60px;
            min-height: 700px;
        }
    }
    &__column--beta {
        @extend .s_p-details__column;
        margin-left: 60px;
        position: relative;
        top: -60px;

        .s_p-details__column__text {
            margin-top: 60px;

            p {
                margin-bottom: 20px;
            }
            a + p {
                margin-top: 20px;
            }
            a {
                color: $c-black2;
                text-decoration: underline;
                font-family: $f-roboto;
            }
        }
    }
    &__care {
        margin-top: 40px;

        .h5 {
            font-size: 2.2rem;
        }
    }
    @media screen and (max-width:$screen__l) {
        margin-top: 50px;

        &__container {
            flex-direction: column;
            padding: 30px;
        }
        &__column {
            width: 100%;
        }
        &__column--alpha, &__column--beta {
            top: 0;
            margin-right: 0;
            margin-left: 0;
            max-width: none;
            display: flex;
            flex-direction: column;

            .s_p-details__column__text {
                margin-top: 0;
                margin-bottom: 0;
                padding: 34px 16px;
            }
        }
        &__column--alpha {
            .s_p-details__column__text {
                padding-bottom: 30px;
                min-height: 0;
            }
            .s_p-details__column__image {
                order: -1;
            }
        }
        &__column--beta {
            .s_p-details__column__text {
                padding-top: 30px;
            }
            .s_p-details__column__image {
                order: 1;
            }
        }
    }
}
.product-lifestyle-banner {
    margin-top: 140px;
    margin-bottom: 140px;
}
