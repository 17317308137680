//
//  Variables
//  _____________________________________________

$account-nav-background: $sidebar__background-color !default;
$account-nav-color: false !default;

$account-nav-current-border: 3px solid transparent !default;
$account-nav-current-border-color: $color-orange-red1 !default;
$account-nav-current-color: false !default;
$account-nav-current-font-weight: $font-weight__semibold !default;

$account-nav-delimiter__border-color: $color-gray82 !default;

$account-nav-item-hover: $color-gray91 !default;

$_password-default: $color-gray-light01 !default;
$_password-weak: #ffafae !default;
$_password-medium: #ffd6b3 !default;
$_password-strong: #c5eeac !default;
$_password-very-strong: #81b562 !default;

// ------------------------------------------------
// SHAN start
// ------------------------------------------------
.customer-account-login,
.customer-account-create,
.customer-account-forgotpassword,
.customer-account-createpassword,
.sales-guest-form,
.aw_rma-guest-index,
.aw_rma-guest-createrequest,
.contact-index-index {

    .page-main {
        @extend .s_container--medium;
        padding-top: 80px;
        box-sizing: border-box;
    }

    .s_craftsmanship {
        display: none;
    }

    .login-container {
        display: flex;
        padding-bottom: 40px;

        > .block {
            width: 50%;
        }
    }

    .block-title {

        p {
            font-size: 26px;
            margin-bottom: 40px;
            font-family: $f-raleway;
            text-align: center;
        }
    }

    .block-customer-login {
        padding-right: 14%;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            display: block;
            right: -.5px;
            top: 0;
            width: 1px;
            height: 100%;
            background-color: $c-chromatic3;
        }
    }

    .block-new-customer {
        padding-left: 14%;

        .actions-toolbar {

            .primary {
                width: 100%;
                display: block;
                float: none;
                margin-top: 0;
            }
        }
    }

    .login {

        .field {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 0;

            .label,
            .control {
                width: 100%;
            }

            .label {
                text-align: left;
                margin-bottom: 6px;
            }
        }

        .field {
            margin-top: 20px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .actions-toolbar {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0 !important;
            margin-top: 32px;

            .primary {
                width: 100%;
            }

            .secondary {
                margin-top: 16px;

                .action {
                    color: $c-chromatic5;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .password.forget,
    .password.reset,
    .form-orders-search,
    .form.contact {
        max-width: 520px;
        margin: 0 auto;

        .field.note {
            font-size: 26px;
            margin-bottom: 40px;
            font-family: $f-raleway;
            text-align: center;
        }

        .captcha {

            .captcha-image {
                display: flex;
                align-items: flex-start;
                margin-top: 20px;
            }

            .action.reload {
                margin-top: 0;
                margin-left: 16px;
            }
        }

        #password-strength-meter-container {
            margin-top: 10px;
        }

        .actions-toolbar {
            margin-left: 0;
            flex-direction: row-reverse;

            .secondary {

                .action.back {
                    margin-top: 0;

                    &:before {
                        content: url(../images/icons/link-arrow-black.svg);
                        margin-right: 10px;
                        vertical-align: sub;
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }

    .create.account {
        max-width: 520px;
        margin: 0 auto;

        legend {
            font-size: 26px;
            margin-bottom: 40px;
            font-family: $f-raleway;
            text-align: center;
            float: none;
            margin-bottom: 10px;
        }

        #password-strength-meter-container {
            margin-top: 10px;
        }

        .actions-toolbar {
            margin-left: 0;
            margin-top: 30px;
            flex-direction: row-reverse;

            .secondary {

                .action.back {
                    margin-top: 0;
                }
            }
        }
    }

    .control.captcha-image {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        button {
            margin: 0;
            vertical-align: top;
            @extend .button--transparent-white;
        }
    }

    @media screen and (max-width: $screen__l) {

        .block-customer-login {
            padding-right: 5%;
        }

        .block-new-customer {
            padding-left: 5%;
        }
    }

    @media screen and (max-width: $screen__m) {
        .login-container {
            flex-direction: column;
            align-items: center;

            > .block {
                width: 70%;
            }
        }

        .block-customer-login {
            padding-right: 0;
            padding-bottom: 80px;

            &:after {
                content: none;
            }
        }

        .block-new-customer {
            padding-left: 0;
        }
    }

    @media screen and (max-width: $screen__s) {
        .login-container {

            > .block {
                width: calc(100% - 60px);
            }
        }
    }
}

body.account {

    .page.messages {
        margin-bottom: 20px;
    }

    .fieldset > .field.required > .label:after,
    .fieldset > .field._required > .label:after,
    .fieldset > .fields > .field.required > .label:after,
    .fieldset > .fields > .field._required > .label:after {
        content: none;
    }

    .page-main {
        @extend .s_container--medium;
        padding-top: 80px;
        box-sizing: border-box;

        .actions-toolbar {
            margin-left: 0;
            justify-content: space-between;
            margin-top: 25px;

            .secondary {
                order: 1;
            }

            .primary {
                order: 2;
            }
        }
    }

    .sidebar.sidebar-main {
        width: 30%;

        .page-title {
            position: relative;
            font-size: 20px;
            font-family: $f-roboto-medium;
            margin-bottom: 20px;
            margin-right: 0;

            &:after {
                content: '';
                display: none;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: transform .24s ease-in-out;
                background-image: url('../images/icons/arrow-down-black.svg');
                background-size: 20px 20px;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
            }
        }

        .nav.item {
            margin-bottom: 16px;

            span,
            strong,
            a {
                font-size: 16px;
                color: $c-chromatic7;
            }

            strong {
                font-family: $f-roboto-medium;
                font-weight: normal;
            }
        }

        @media screen and (max-width: $screen__m) {
            order: -1;

            .page-title {
                cursor: pointer;

                &:after {
                    display: block;
                }

                &.opened {

                    &:after {
                        transform: translateY(-50%) rotate(-180deg);
                    }
                }
            }

            .account-nav-content {
                margin-bottom: 40px;
            }

            .nav.items {
                height: 0;
                overflow-y: hidden;
                transition: height .24s ease-in-out;
            }
        }
    }

    .column.main {
        width: 70%;

        form {

            .field {
                max-width: 640px;
            }

            .field.password-info {
                margin-bottom: 40px;
            }

            .password-strength-meter {
                font-size: 12px;
                margin-top: 4px;
            }
        }

        .block {
            margin-bottom: 70px;
        }

        .block,
        fieldset {
            position: relative;
        }

        .block-title,
        legend {
            font-size: 26px;
            font-family: $f-raleway;
            margin-bottom: 30px;
            display: inline-block;
            background-color: $c-white;
            padding-right: 36px;

            strong,
            span {
                font-weight: normal;
            }

            .action {
                color: $c-chromatic5;
                margin-left: 20px;
            }

            &:after {
                content: '';
                position: absolute;
                right: 0;
                top: 22px;
                height: 1px;
                width: 100%;
                background-color: $c-chromatic3;
                z-index: -1;
            }
        }

        legend {

            &:after {
                top: 16px;
            }
        }

        .block-content {
            display: flex;
            justify-content: space-between;

            .box {
                width: 42%;

                &-title {
                    display: inline-block;
                    font-weight: normal;
                    font-family: $f-roboto-medium;
                    margin-bottom: 16px;
                    font-size: 18px;
                }

                &-content {
                    font-size: 16px;
                }

                .action {
                    color: $c-chromatic5;
                    display: inline-block;
                    margin-top: 10px;
                }

                .action + .action {
                    margin-left: 20px;
                }
            }
        }

        .block-addresses-list {

            .block-content {
                display: block;
            }
        }

        .col.actions {

            a {
                display: block;
            }
        }
    }

    .form-wishlist-items,
    .form-edit-account,
    .form-address-edit,
    &.sales-order-history,
    &.customer-address-index,
    .form-newsletter-manage,
    .order-details-items {

        .action.back {

            &:before {
                content: url('../images/icons/link-arrow-black.svg');
                margin-right: 15px;
                vertical-align: sub;
            }
        }
    }

    .column.main .amgiftcard-form {
        width: 100%;
        max-width: none;
    }

    .amgiftcard .fieldset {
        width: 100%;

        .field {
            max-width: 640px;
        }
    }

    .order-actions-toolbar {
        padding-bottom: 20px;
        border-bottom: 1px solid $c-chromatic3;
        max-width: 75%;

        @media screen and (max-width: $screen__m) {
            max-width: 100%;
        }
    }

    .table-order-items {

        > tbody > tr > td:first-of-type,
        > thead > tr > th:first-of-type {
            padding-left: 0;
        }

        > tbody > tr > td:last-of-type,
        > thead > tr > th:last-of-type {
            padding-right: 0;
        }
    }

    &.sales-order-view {

        .column.main {
            display: flex;
            flex-direction: column;

            .block-order-details-view {
                order: -1;
            }
        }

        @media screen and (max-width: $screen__m) {

            .column.main {

                .block-order-details-view {
                    margin-bottom: 40px;

                    .block-content {
                        display: flex;
                        flex-direction: column;

                        .box {
                            margin-bottom: 30px;

                            &:last-of-type {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .order-details-items {
        border-bottom: 0;
    }

    .orders-recent {
        width: 100%;
    }
}

.customer-account-logoutsuccess {

    .column.main {
        @extend .s_container--small;
        text-align: center;
        margin-top: 60px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
