$container-padding: 30px;

* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

body {
    color: $c-black2;
}

body.modal-opened {
    overflow: hidden;
}

body.ajax-loading {

}

.page-wrapper .page-main {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 48px;

    @media screen and (min-width:$screen__l + 1) {
        padding-top: 100px;
    }

    @media screen and (min-width:$screen__ml - 1) {
        padding-top: 84px;
    }
}

.s_container--small {
    max-width: 1030px + $container-padding + $container-padding;
    width: 100%;
    margin: 0 auto;
    padding-left: $container-padding;
    padding-right: $container-padding;

    @media screen and (max-width: $screen__s) {
        padding-left: $container-padding / 2;
        padding-right: $container-padding / 2;
    }
}

.s_container--medium {
    max-width: 1200px + $container-padding + $container-padding;
    width: 100%;
    margin: 0 auto;
    padding-left: $container-padding;
    padding-right: $container-padding;

    @media screen and (max-width: $screen__s) {
        padding-left: $container-padding / 2;
        padding-right: $container-padding / 2;
    }
}

.s_container {
    max-width: 1520px + $container-padding + $container-padding;
    width: 100%;
    margin: 0 auto;
    padding-left: $container-padding;
    padding-right: $container-padding;

    @media screen and (max-width: $screen__s) {
        padding-left: $container-padding / 2;
        padding-right: $container-padding / 2;
    }
}

.s_container--large {
    max-width: 1800px + $container-padding + $container-padding;
    width: 100%;
    margin: 0 auto;
    padding-left: $container-padding;
    padding-right: $container-padding;

    @media screen and (max-width: $screen__s) {
        padding-left: $container-padding / 2;
        padding-right: $container-padding / 2;
    }
}

.s_a-full {
    display: inline-block;
    padding: 12.5px 40px;
    background-color: $c-black2;
    border: 2px solid $c-black2;
    transition: background-color 0.24s ease, border-color 0.24s ease;
    color: $c-white;

    &:hover {
        background-color: $c-chromatic7;
        border-color: $c-chromatic7;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited {
        color: $c-white;
        text-decoration: none;
    }
}

.s_a-underline {
    text-transform: uppercase;
    position: relative;
    color: $c-black2;
    text-decoration: none;
    letter-spacing: 1px;
    font-family: $f-roboto-medium;

    &:after {
        content: "";
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        bottom: -5px;
        color: $c-black2;
        background-color: $c-black2;
        transition: transform 0.24s ease;
    }

    &:hover,
    &:focus {

        &:after {
            transform: scaleX(0.85);
        }
    }

    &:hover,
    &:focus,
    &:active,
    &:visited {
        color: $c-black2;
        text-decoration: none;
    }

    &--white {
        @extend .s_a-underline;
        color: $c-white;

        &:after {
            color: $c-white;
            background-color: $c-white;
        }

        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: $c-white;
        }
    }
}

.s_a-border {
    @extend .s_a-full;
    border: 2px solid $c-chromatic3;
    background-color: transparent;
    color: $c-chromatic7;

    &:hover {
        color: $c-black2;
        background-color: $c-chromatic3;
        border-color: $c-chromatic3;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited {
        color: $c-black2;
        background-color: $c-chromatic3;
        text-decoration: none;
    }
}

.s_a-grey {
    color: $c-chromatic5;

    &:hover {
        text-decoration: underline;
        color: $c-chromatic5;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited {
        text-decoration: underline;
        color: $c-chromatic5;
    }
}

.button-as-link {
    padding: 0 !important;
    background-color: transparent;
    border: 0;
    margin: 0;

    &:focus,
    &:hover {
        background-color: transparent;
        border: 0;
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0;
    font-family: $f-raleway;
    font-weight: 400;

    @media screen and (max-width: $screen__m) {

        br,
        br:after {
            content: " ";
        }
    }
}

h1,
.h1 {
    font-size: $fz-h1;
    //line-height: 1.25;

    @media screen and (max-width: $screen__m) {
        font-size: $fz-h1-sm;
    }
}

h2,
.h2 {
    font-size: $fz-h2;
    //line-height: 1.3;

    @media screen and (max-width: $screen__m) {
        font-size: $fz-h2-sm;
    }
}

h3,
.h3 {
    font-size: $fz-h3;
    //line-height: 1.18;

    @media screen and (max-width: $screen__m) {
        font-size: $fz-h3-sm;
    }
}

h4,
.h4 {
    font-size: $fz-h4;
    //line-height: 1.3;

    @media screen and (max-width: $screen__m) {
        font-size: $fz-h4-sm;
    }
}

h5,
.h5 {
    font-size: $fz-h5;
    //line-height: 1.16;

    @media screen and (max-width: $screen__m) {
        font-size: $fz-h5-sm;
    }
}

h6,
.h6 {
    font-size: $fz-h6;
    //line-height: 1.2;

    @media screen and (max-width: $screen__m) {
        font-size: $fz-h6-sm;
    }
}

p,
.p {
    color: $c-black2;
    font-family: $f-roboto;
    font-size: $fz-p;
    line-height: 1.5;
    margin: 0;

    @media screen and (max-width: $screen__m) {
        font-size: $fz-p-sm;
    }
}

a,
.a {
    color: $c-black2;
    font-size: $fz-a;
    font-family: $f-roboto;

    &:visited {
        color: $c-black2;
    }

    &:hover {
        text-decoration: none;
    }

    @media screen and (max-width: $screen__m) {
        font-size: $fz-a-sm;
    }
}

ul,
li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

li {
    font-size: $fz-a;
    line-height: 1.5;
}

.message {
    color: $c-white;

    &.info {
        background-color: $c-notif;
    }

    &.error {
        background-color: $c-error;
        margin: 0 !important;
    }

    &.success {
        background-color: $c-success;
    }

    span {

        &:before {
            color: $c-white;
        }
    }
}

// owl carousel override
.owl-theme {

    .owl-dots {

        .owl-dot {

            &:active {
                box-shadow: none;
            }

            span {
                background-color: $c-black2;
                border: 1px solid transparent;
                transition: background-color 0.24s ease, border-color 0.24s ease, transform 0.24s ease;
                width: 8px;
                height: 8px;
            }

            &.active {

                span {
                    background-color: transparent;
                    border-color: $c-black2;
                    transform: scale(1.6);
                }
            }

            &:hover {

                span {
                    background-color: $c-black2;
                }
            }
        }
    }
}

.modal-popup {
    pointer-events: all !important;
}

.modal {
    position: fixed;
    z-index: 1000;
    background: rgba($c-white, 0.95);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.48s ease-in-out;
    overflow-y: auto;

    &.opened {
        visibility: visible;
        pointer-events: all;
        opacity: 1;
    }

    &.closing {
        opacity: 0;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
        z-index: 100;
        border: 2px solid $c-black2;
        border-radius: 100%;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        svg #close-fill {
            fill: $c-black2;
        }
    }

    &__wrapper {
        position: absolute;
        width: 100%;
        max-width: 960px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 4px;
    }

    &__content {
        padding: 40px;
        position: relative;
    }

    @media screen and (max-width: $screen__m) {
        overflow-y: auto;

        &__wrapper {
            max-width: none;
            max-height: none;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translate(0, 0);
        }
    }

    @media screen and (max-width: $screen__xs) {

        &__content {
            padding: 15px;
        }
    }
}

.modal#geoip {
    background-image: url('../images/geoip-bg.jpg');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $c-white;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($c-black, 0.8)
    }

    .modal__close {
        border-color: $c-white;

        svg #close-fill {
            fill: $c-white;
        }
    }

    .modal__content {
        text-align: center;

        h3 {
            margin-bottom: 40px;
        }

        .choices {
            max-width: 350px;
            margin: 0 auto;
        }

        .store {
            border: 2px solid $c-white;
            height: 72px;
            line-height: 68px;
            margin-bottom: 30px;
            cursor: pointer;
            font-size: 1.5rem;
            background-color: rgba($c-black, 0.5);
            transition: color 0.24s ease, background-color 0.24s ease, opacity 0.24s ease;

            &.outside-detected-location {
                opacity: 0.2;
            }

            > span {
                display: block;
                color: $c-white;
                background-color: rgba($c-black, 0.5);
                transition: color 0.24s ease, background-color 0.24s ease, opacity 0.24s ease;
            }

            &:hover {
                opacity: 1;

                > span {
                    color: $c-black;
                    background-color: $c-white;
                }
            }

            &.selected {

                > span {
                    display: none;
                }

                .languages {
                    display: flex;
                }
            }
        }

        .languages {
            display: none;

            a {
                display: block;
                width: 100%;
                color: $c-black;
                background-color: $c-white;
                transition: color 0.24s ease, background-color 0.24s ease;

                &.not-hover {
                    color: $c-white;
                    background-color: transparent;
                }
            }
        }
    }
}

.modal#size-guide {

    .modal__wrapper {
        top: 0;
        transform: translateX(-50%);
    }

    @media screen and (max-width: $screen__m) {

        .modal__wrapper {
            left: 50%;
        }
    }
}


.loading-mask {

    .loader {
        $loader-size: 38px;

        img {
            box-sizing: border-box;
            width: $loader-size;
            height: $loader-size;
            padding-left: $loader-size;
            background-image: url('../images/icons/loader.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

// Recaptcha
.grecaptcha-badge {
    visibility: hidden;
}

.recaptcha-message {
    margin-top: 8px;

    p {
        font-size: 13px;

        .recaptcha-link {
            text-decoration: underline;
        }
    }
}
