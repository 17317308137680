
.s_giftcard-landing {

    &__text {
        padding: 0 30px;
        max-width: 700px;
        margin: 35px auto 0 auto;
        text-align: center;

        p {
            margin-top: 23px;
        }
    }

    &__cards {
        display: flex;
        justify-content: center;
        margin-top: 70px;
    }

    &__card {
        width: 100%;
        max-width: 654px;
        padding: 110px 79px 70px 79px;
        margin: 0 25px;
        text-align: center;
        background-color: $c-chromatic1;

        a {
            color: $c-black2;

            &:hover,
            &:focus,
            &:active {
                text-decoration: none;
            }

            p {
                color: $c-chromatic5;
            }
        }

        h2 {
            margin-top: 58px;
            margin-bottom: 16px;
        }
    }

    @media screen and (max-width: $screen__l) {

        &__card {
            padding: 26px;
            margin: 0 12px;

            h2 {
                margin-top: 20px;
            }
        }
    }

    @media screen and (max-width: $screen__m) {
        padding-left: 0;
        padding-right: 0;

        &__cards {
            flex-direction: column;
        }

        &__card {
            padding: 26px;
            margin-bottom: 30px;
            margin-left: 0;
            margin-right: 0;
            max-width: none;
        }
    }
}
