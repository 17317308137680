//
//  Page components
//  ---------------------------------------------

@import 'checkout/checkout';
@import 'checkout/estimated-total';
@import 'checkout/progress-bar';
@import 'checkout/fields';
@import 'checkout/modals';
@import 'checkout/tooltip';
@import 'checkout/shipping';
@import 'checkout/shipping-policy';
@import 'checkout/sidebar';
@import 'checkout/sidebar-shipping-information';
@import 'checkout/order-summary';
@import 'checkout/authentication';
@import 'checkout/payments';
@import 'checkout/payment-options';
@import 'checkout/checkout-agreements';

// override
.abs-checkout-title, .opc-wrapper .step-title, .opc-block-shipping-information .shipping-information-title, .opc-block-summary > .title, .checkout-agreements-items .checkout-agreements-item-title {
    border-bottom: 0;
    font-size: 2.2rem;
    font-family: $f-raleway;
    color: $c-black2;
}
.checkout-index-index .modal-popup .modal-inner-wrap {
    width: 100%;
    max-width: 1030px;
}
.opc-wrapper {
    .fieldset > .field.required > .label:after, .fieldset > .field.required > label:after, .fieldset > .field._required > .label:after, .fieldset > .field._required > label:after, .fieldset > .fields > .field.required > .label:after, .fieldset > .fields > .field.required > label:after, .fieldset > .fields > .field._required > .label:after, .fieldset > .fields > .field._required > label:after {
        content: none;
    }
    .field .control._with-tooltip {
        input {
            width: 100%;
        }
        .field-tooltip {
            display: none;
        }
    }
}
.opc-wrapper .form-login, .opc-wrapper .form-shipping-address {
    max-width: none;
    margin-bottom: 12px;
    margin-top: 7px;
}
.opc-wrapper .form-discount {
    max-width: none !important;
}
.column:not(.sidebar-additional) form .actions-toolbar, .column:not(.sidebar-main) form .actions-toolbar {
    margin-left: 0;
}
.opc-wrapper .fieldset > .field > .label {
    font-family: $f-roboto-medium;
}
.minicart-items .product-item-details {
    margin-top: 0;
    padding-left: 70px;

    .price-excluding-tax {
        margin-top: 0;
    }
}
.opc-block-summary .table-totals .totals-tax-summary .amount .price {
    padding-right: 0;

    &:after {
        content: none;
    }
}
.opc-block-summary .table-totals .totals-tax-summary .mark, .opc-block-summary .table-totals .totals-tax-summary .amount {
    border: 0;
    cursor: default;
}
.checkout-payment-method .ccard .number .input-text {
    width: 100%;
}
.checkout-payment-method .ccard .month .select {
    width: 100%;
}
.checkout-index-index {
    .page-wrapper {
        padding-top: 30px;
    }
    .product-item {
        width: 100%;
    }
    .actions-toolbar {
        display: flex;
        flex-direction: row-reverse;
        //max-width: 560px;

        > .primary {
            border: none;
            width: 50%;
        }
        > .secondary {
            padding: 0 10px;
            white-space: nowrap;
        }
    }
    .back-link {
        &:before {
            content: url("../images/icons/link-arrow.svg");
            margin-right: 10px;
            vertical-align: sub;
        }
    }
    .modal-popup {
        background-color: rgba($c-white, 0.95);

        &._inner-scroll {
            overflow-y: scroll;

            .modal-inner-wrap {
                max-height: none;
            }
        }
        .modal-inner-wrap {
            margin-left: 0;
            transform: translateX(-50%);
            left: 50%;
            background-color: transparent;
            box-shadow: none;
        }
        .modal-title {
            border-bottom: 0;
        }
        .modal-content {
            text-align: center;

            .field {
                text-align: left;
            }
        }
        .modal-footer {
            text-align: right;
            border-top: 0;

            button {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        .form-shipping-address {
            max-width: none;
        }
    }
    .checkout-footer {
        max-width: 560px;
        padding-right: 30px;
        width: 100%;
    }
    .footer__small-links__container {
        text-align: center;
        color: $c-chromatic5;
        padding-top: 30px;
        margin: 50px 0;
        border-top: 1px solid $c-chromatic3;

        .footer__small-links__logo {
            display: none;
        }
        #switcher-language {
            display: none;
        }
        .copyright {
            font-size: 1.4rem;
        }
        .footer__small-links__misc {
            margin-top: 10px;

            a {
                margin: 0 5px;
                color: $c-chromatic5;
            }
        }
    }
    .secure {
        display: block;
        text-align: right;
        margin-top: 30px;

        &:before {
            content: url('../images/icons/lock.svg');
            margin-right: 10px;
        }
    }
}
// custom styling
.checkout-alpha {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 30px;
    padding-right: calc(50% + 30px);

    .checkout-continue-shopping {
        margin-bottom: 50px;
    }
    > a {
        display: block;
        width: 100%;
        max-width: 560px;

        svg path {
            fill: $c-black2;
        }
    }
    .opc-wrapper {
        float: none;
        width: 100%;
    }
    .s_a-grey {
        padding: 0 !important;
        background-color: transparent;
        margin: 0;
        border: none;
    }
    .action-show-popup {
        margin-top: 15px;
        text-transform: uppercase;
        background-color: transparent;
        padding: 0 0 5px 0 !important;
        border-bottom: 1px solid $c-black;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        color: $c-black;

        > span:before {
            content: none;
        }
    }
    .label {
        white-space: nowrap;
    }
}
.opc-progress-bar {
    margin-top: 20px;
    padding-right: 30px;
}
.opc-progress-bar-item {
    width: auto !important;
    padding-left: 20px;
    margin-right: 15px !important;

    &:before {
        content: none;
    }
    &:after {
        content: "/";
        position: absolute;
        right: -10px;
        display: block;
        top: 0;
        font-size: 1.4rem;
        font-family: $f-roboto-bold;
        color: $c-chromatic5;
    }
    &:last-of-type:after {
        content: none;
    }
    > span {
        padding-top: 0;
        position: relative;
        font-size: 1.4rem;
        color: $c-chromatic5;
        font-family: $f-roboto-bold;

        &:before {
            content: none;
        }
        &:after {
            content: counter(i) '.';
            width: auto;
            height: auto;
            left: -6px;
            top: 0;
            background-color: transparent;
            font-size: 1.4rem;
            color: $c-chromatic5;
            font-weight: 400;
        }
    }
    &._active {
        color: $c-black2;

        > span:after {
            content: inherit;
            font-family: inherit;
            line-height: inherit;
            font-size: inherit;
            color: $c-black2;
        }
    }
    &._complete {
        > span {
            color: $c-chromatic5;
        }
    }
}
.checkout-container {
    max-width: 560px;
    width: 100%;
}
.opc-sidebar {
    width: 50%;
    position: fixed;
    top: 0;
    right: 0;
    padding-left: 5%;
    padding-right: 30px;
    margin: 0;
    height: 100%;
    overflow-y: scroll;
    border-left: 1px solid $c-chromatic3;
    background-color: $c-white2;

    > .modal-inner-wrap {
        max-width: 440px;
    }
}
.opc-block-summary {
    background-color: transparent;
    margin-top: 28px;
    padding-left: 0;
    padding-right: 0;

    > .title {
        font-size: 2.6rem;
    }
    .minicart-items-wrapper {
        margin: 0;
        max-height: none;
        padding: 0;
    }
    .product-item {
        padding: 30px 0 30px;
        border-bottom: 1px solid $c-chromatic3;

        &:first-child {
            padding-top: 30px;
        }
        .product-item-inner {
            display: flex;
            justify-content: space-between;
        }
        .product-item-name-block {
            padding: 0;
            display: block;
            line-height: 1;
        }
        .product-item-name {
            font-family: $f-raleway;
            font-size: 1.6rem;
        }
        .item-options {
            li {
                margin-bottom: 0;
                color: $c-chromatic5;
            }
        }
        .price {
            font-size: 1.5rem;
        }
    }
    .table-totals {
        margin-top: 12px;
        font-size: 1.5rem;

        > tbody > tr > th, > tbody > tr > td {
            padding: 6px 10px;
        }
    }
    .spacer {
        td {
            padding-top: 2px;
        }
    }
    .grand.totals {
        .mark, .amount {
            border-top: 1px solid $c-chromatic3;
            font-size: 1.7rem;
            padding-top: 25px;
        }
        .amount {
            font-family: $f-roboto-bold;
            font-size: 2.2rem;
        }
    }
}
.form.methods-shipping {
    .actions-toolbar {
        display: none;
    }
}
.form-login {
    .hidden-fields {
        margin-bottom: 30px;
    }
    .note {
        color: $c-chromatic5;
        margin-top: 15px !important;

        &:before {
            content: none !important;
        }
    }
}
.s_shipping-method {
    margin-top: 10px;
    margin-bottom: 25px;
    //max-width: 560px;

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;
        border-top: 1px solid $c-chromatic3;
        cursor: pointer;

        &:last-of-type {
            border-bottom: 1px solid $c-chromatic3;
        }
        .col-info {
            flex-grow: 1;
            padding: 0 15px;

            p, span {
                display: inline;
            }
        }
        .price {
            font-size: 1.4rem;
            font-family: $f-roboto-bold;
        }
    }
}
.opc-wrapper .shipping-address-items {
    display: flex;
    flex-direction: column;
    //max-width: 560px;

    .shipping-address-item {
        width: 100%;
        border: none;
        background-color: $c-white2;
        display: flex;
        justify-content: space-between;

        &:before {
            content: none;
        }
        &.selected-item {
            border: 2px solid $c-notif;

            &:after {
                content: none;
            }
        }
    }
}
.opc-block-shipping-information {
    padding: 0;

    .shipping-information-title {
        padding-bottom: 0;
        margin-bottom: 15px;
        display: flex;
        align-items: baseline;

        > span {
            margin-right: 20px;
        }
    }
    .name {
        font-size: 1.8rem;
        font-family: $f-roboto-medium;
        margin-bottom: 10px;
    }
    .s_a-grey {
        text-decoration: underline;
    }
}
// Shipping form
#shipping-new-address-form {
    [name="shippingAddress.firstname"] {
        width: 50%;
        padding-right: 15px;
        float: left;
    }
    [name="shippingAddress.lastname"] {
        width: 50%;
        float: right;
    }
    [name="shippingAddress.company"] {
        clear: both;
    }
    [name="shippingAddress.street.0"], [name="shippingAddress.street.1"] {
        label {
            display: none;
        }
    }
    [name="shippingAddress.city"] {
        width: 66%;
        float: left;
        padding-right: 15px;
    }
    [name="shippingAddress.postcode"] {
        width: 33%;
        float: right;
    }
    [name="shippingAddress.region_id"] {
        width: 50%;
        float: left;
        padding-right: 15px;
    }
    [name="shippingAddress.country_id"] {
        width: 50%;
        float: right;
    }
    [name="shippingAddress.telephone"] {
        clear: both;
    }
}
// Shipping form
.billing-address-form {
    [name="billingAddresspayflowpro.firstname"] {
        width: 50%;
        padding-right: 15px;
        float: left;
    }
    [name="billingAddresspayflowpro.lastname"] {
        width: 50%;
        float: right;
    }
    [name="billingAddresspayflowpro.company"] {
        clear: both;
    }
    [name="billingAddresspayflowpro.city"] {
        width: 66%;
        float: left;
        padding-right: 15px;
    }
    [name="billingAddresspayflowpro.postcode"] {
        width: 33%;
        float: right;
    }
    [name="billingAddresspayflowpro.region_id"] {
        width: 50%;
        float: left;
        padding-right: 15px;
    }
    [name="billingAddresspayflowpro.country_id"] {
        width: 50%;
        float: right;
    }
    [name="billingAddresspayflowpro.telephone"] {
        clear: both;
    }
}
#pickup-checkout-form {
    [name="pickupInStore.firstname"] {
        width: 50%;
        padding-right: 15px;
        float: left;
    }
    [name="pickupInStore.lastname"] {
        width: 50%;
        float: right;
    }
    .action-message {
        color: $c-chromatic5;
        font-size: 1.5rem;
        margin-bottom: 10px;
        clear: both;
    }
    .actions-toolbar {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        > .primary {
            width: auto;
        }
    }
    .pickup-address {
        line-height: 1.5;
        margin-top: 15px;

        .header {
            margin-bottom: 15px;
        }
        .store-name {
            font-size: 1.8rem;
            font-family: $f-roboto-medium;
        }
        a {
            margin-left: 10px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
.checkout-success {
    margin-top: 70px;
    text-align: center;

    h1 {
        margin-top: 45px;
        margin-bottom: 20px;
    }
    h2 {
        font-size: 2.8rem;
        font-family: $f-roboto-medium;
        margin-bottom: 20px;
    }
    .checkout-success-create-account {
        margin: 20px 0;
        padding: 60px 30px;
        background-color: $c-white2;

        form {
            margin-top: 20px;

            .action.primary {
                min-width: 360px;
            }
        }
    }
    .continue-shopping {
        margin-top: 70px;
    }
}
.opc-payment {
    //max-width: 560px;

    .payment-trigger {
        .step-title {
            display: none;
        }
        .payment-method-content {
            display: none !important;
        }
        .payment-trigger-list {
            display: flex;
            justify-content: center;
            margin: 20px -5px 0;

            .payment-method {
                flex-basis: 100%;
                align-self: stretch;

                input[type="radio"] {
                    display: none;
                }
                .payment-method-title {
                    padding: 0;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    justify-content: center;
                    margin: 0 5px;
                    border: solid 2px $c-chromatic2;
                    border-radius: 4px;
                    height: 100%;

                    .label {
                        display: block;
                        padding: 30px 15px;
                        cursor: pointer;

                        &[for="payflow_express"] span {
                            display: none;
                        }
                    }
                }
                &._active {
                    .payment-method-title {
                        border-color: $c-notif;
                    }
                }
            }
        }
    }
    .payment-content {
        margin-top: 40px;

        .legend {
            margin-top: 15px;
        }
        .payment-method-title {
            display: none;
        }
        .payment-method-content {
            padding: 0;
        }
        .actions-toolbar {
            margin-top: 40px;

            .primary .primary {
                background-color: $c-notif;
                border-color: $c-notif;
            }
        }
        .payment-method-billing-address {
            padding: 50px 0 0 0;
            margin-top: 40px;
            border-top: 1px solid $c-chromatic3;
        }
        .billing-address-details {
            padding: 0;
        }
        .billing-address-form {
            max-width: none;
        }
    }
    .control-type {
        position: relative;

        input {
            padding-right: 65px;
        }
        .type {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 44px;

            .item {
                margin-right: 3px;
                display: none;

                &._active {
                    display: block;
                }
            }
        }
    }
    .expiration-row {
        display: flex;

        .field.date {
            width: 66%;
        }
        .field.month, .field.year {
            width: calc(50% - 10px);
            float: left;
            margin-right: 10px;
        }
        .field.cvv {
            width: 33%;
        }
        .field .control._with-tooltip input {
            width: 100%;
            margin-right: 0;
            padding-right: 36px;
        }
        .field-tooltip {
            right: 11px;
            top: 6px;
        }
    }
}
#co-payment-form {
    > .fieldset {
        position: relative;

        .amcard-field-container {
            .amcard-field-block {
                margin-top: 10px;

                input.amcard-button {
                    display: inline-block;
                    padding: 15px 45px !important;
                    margin: 15px 0 0 0;
                    color: #FFF;
                    font-size: 14px !important;
                    font-weight: 400;
                    line-height: 1 !important;
                    border-radius: 0;
                    background-color: #121215;
                    font-family: "robotoregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
                    transition: color 0.24s ease, background-color 0.24s ease;
                    border: 2px solid #121215;

                    @media screen and (max-width:$screen__m) {
                        width: 100%;
                    }
                }
            }
            .amcard-check {
                background-color: transparent;
                border: none;
                color: #121215;
                margin: 15px auto;
                text-decoration: underline;
                width: 155px;
                padding: 0 !important;

                @media screen and (max-width:$screen__m) {
                    width: 100%;
                }
            }
        }
    }
}
.checkout-payment-method .payment-option._collapsible.gift-code {
    position: relative;
    width: 100%;
    //max-width: 560px;
    text-align: right;

    .payment-option-title {
        border-top: 0;
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    > .payment-option-title {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(-100%);
        white-space: nowrap;
        cursor: default;

        .action-toggle {
            color: $c-chromatic5;
            padding: 10px 20px !important;
            border-radius: 4px;
            cursor: pointer;

            &:after {
                content: none;
            }
        }
    }
    &._active {
        > .payment-option-title {
            display: none;
        }
        .payment-option-content {
            display: block !important;
        }
    }
    .inner-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        margin-top: 25px;

        > a {
            @extend .s_a-grey;
            text-decoration: underline;
        }
    }
    .actions-toolbar {
        flex-direction: row;

        .primary {
            width: 100%;
        }
        button {
            padding: 0;
        }
    }
    .payment-option-content {
        padding: 0 0 25px;
    }
    .payment-option-inner {
        display: flex;
        text-align: left;

        .field {
            flex-basis: 100%;
            margin-right: 10px;
        }
        .action-apply-gift {
            flex-shrink: 0;
            margin: 0;
            height: 44px;
        }
    }
    .amgiftcard-check {
        width: 100%;
        float: none;

        .gift-code-actions {
            text-align: left;
            float: left;
            width: 100%;

            > .action-apply-gift {
                text-align: left;
                margin-bottom: 15px;
                text-decoration: underline;
            }
        }
        .active-gift-code {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            background: $c-white2;

            span.code {
                font-family: $f-roboto-medium;
                margin-right: 5px;
            }
        }
        .amgiftcard_info {
            width: 100%;

            .amgiftcard-info {
                display: flex;
                justify-content: space-between;
                padding: 10px;
                background: $c-white2;
            }
        }
    }
}
.checkout-payment-method .payment-option.discount-code {
    margin-top: 50px;

    .payment-option-title {
        font-family: $f-roboto-medium;
        padding-left: 0;
        margin-bottom: 5px;
        padding-top: 30px;
        padding-bottom: 10px;
        font-size: 1.4rem;
        text-transform: none;
        letter-spacing: 0;
    }
    .payment-option-content {
        padding-left: 0;
        margin-bottom: 0;
    }
    .form-discount {
        display: flex;

        .payment-option-inner {
            flex-basis: 100%;
        }
        .actions-toolbar {
            flex-basis: 20%;
            margin-left: 20px;
            position: relative;
            top: -1px;

            .primary {
                width: 100%;
            }
        }
    }
    .payment-option-inner {
        margin-bottom: 0;
    }
}
@media screen and (max-width:$screen__m) {
    .checkout-alpha {
        padding-right: 30px
    }
    .opc-estimated-wrapper {
        border: 2px solid $c-chromatic3;
        background-color: $c-white2;
        border-radius: 4px;
        padding: 15px 10px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:before, &:after {
            content: none !important;
        }
        .estimated-block {
            display: flex;
            font-weight: normal;
            font-size: 1.6rem;
            float: none;

            .estimated-label {
                margin-right: 10px;
                margin-bottom: 0;
                text-align: left;
            }
        }
        .minicart-wrapper {
            float: none;
            margin-top: 0 !important;
        }
        button {
            margin: 0;
        }
        .minicart-wrapper .action.showcart {
            padding: 0 30px 0 0 !important;
            color: $c-chromatic5;
            background-color: transparent;
            border: none;

            &:before {
                content: none;
            }
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 24px;
                height: 24px;
                right: 0;
                display: inline-block;
                background-image: url('../images/icons/cart-black.svg');
                background-size: 24px 24px;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
    }
    .opc-wrapper .form-login, .opc-wrapper .form-shipping-address {
        max-width: none;
    }
    .opc-wrapper .form-discount {
        max-width: none !important;
    }
    .checkout-index-index {
        .actions-toolbar {
            max-width: none;
        }
        .checkout-footer {
            max-width: none;
        }
    }
    .opc-wrapper .shipping-address-items {
        max-width: none;
    }
    .s_shipping-method {
        max-width: none;
    }
    .opc-payment {
        max-width: none;
    }
    .checkout-payment-method {
        .payment-methods {
            margin: 0;
        }
        .payment-option {
            margin: 0;
        }
        .payment-option._collapsible.gift-code {
            max-width: none;

            .payment-option-title {
                padding-right: 0;
            }
        }
    }
    .opc-sidebar {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        left: 0;

        > .modal-inner-wrap {
            max-width: none;
        }
        .modal-content {
            padding: 0 30px;
        }
    }
    .checkout-container {
        .modal-custom-overlay {
            background-color: transparent;
        }
        .custom-slide {
            opacity: 1;
            visibility: visible;
            transform: translateX(100%);
            transition: transform 0.72s ease;

            &._show {
                transform: translateX(0);
            }
        }
        .custom-slide .modal-inner-wrap {
            transform: translateX(0) !important;
        }
    }
    .checkout-index-index {
        .action-close {
            margin: 25px;
            padding: 0 !important;
            border-radius: 50%;
            border: 2px solid $c-black2 !important;

            &:before {
                color: $c-black2;
            }
        }
        .secure {
            text-align: left;
        }
        .modal-popup {
            left: 0;

            .modal-title {
                font-size: 24px;
                font-weight: 100;
            }
            &._inner-scroll {
                //overflow-y: scroll;

                .modal-inner-wrap {
                    //max-height: none;
                }
            }
            .modal-inner-wrap {
            }
        }
        .modal-popup.modal-slide .modal-inner-wrap[class] {
            background-color: transparent;
        }
        .footer__small-links__container {
            margin-bottom: 0;
        }
        .minicart-items .product-item:not(:first-child) {
            border-top: none;
        }
    }
    .opc-sidebar {
        background-color: rgba($c-white, 0.95);
    }
    .custom-slide .modal-inner-wrap {
        background-color: transparent;
    }
}
@media screen and (max-width:$screen__s) {
    .minicart-wrapper {
        margin-top: 0 !important;
    }
    .opc-wrapper .form-login {
        border-bottom: 0;
    }
    .opc-wrapper .step-title, .opc-wrapper .checkout-payment-method .payments .legend, .checkout-payment-method .payments .opc-wrapper .legend {
        padding-bottom: 10px;
    }
    .opc-payment .expiration-row {
        flex-wrap: wrap;

        .field.date {
            width: 100%;
        }
        .field.year {
            width: 50%;
            margin-right: 0;
        }
        .field.cvv {
            width: 100%;
        }
    }
    .opc-estimated-wrapper {
        flex-direction: column;

        .estimated-block {
            margin-bottom: 10px;
        }
    }
    .checkout-payment-method .payment-option._collapsible.gift-code {
        text-align: left;

        > .payment-option-title {
            position: static;
            top: auto;
            right: auto;
            transform: translateY(0);
        }
    }
    .checkout-index-index .actions-toolbar {
        flex-direction: column-reverse;

        .secondary {
            margin-bottom: 20px;
        }
        > .primary {
            width: 100%;
        }
    }
    .opc-payment .payment-trigger-list {
        flex-direction: column !important;
    }
    .opc-payment .payment-trigger-list .payment-method .payment-method-title .label {
        padding: 25px 15px !important;
    }
}
