
.cms-noroute-index {

  .columns:after {
    content: none;
  }

  .sidebar {
    display: none !important;
  }

  .column.main {
    text-align: center;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 600px;
    margin-top: 50px;
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center center;

    h1 {
      font-family: $f-roboto-bold;
      font-size: 8rem;
      color: #deb69c;
    }
  }

  .actions {
    margin-top: 40px;
  }

  @media screen and (max-width: $screen__l) {

    .title {
      background-size: 100%;
    }
  }

  @media screen and (max-width: $screen__m) {

    .title {
      background-size: 150%;
      min-height: 500px;

      h1 {
        font-size: 5rem;
      }
    }
  }

  @media screen and (max-width: $screen__xs) {

    .title {
      min-height: 300px;
    }
  }
}

.cms-page-view {

  .column.main {
    padding-bottom: 0;
  }

  a.grey {
    color: $c-chromatic9;
    text-decoration: underline;
    font-size: 16px;
    margin-right: 10px;

    &:hover {
      color: $c-black;
    }
  }
}

.cms-page-view .content {
  padding-top: 30px;
  @extend .s_container--small;

  h2 {
    margin-top: 40px;
    margin-bottom: 16px;
  }

  b, strong {
    font-size: $f-roboto-bold;
  }

  i {
    font-size: $f-roboto;
    font-style: italic;
  }

  .content__container {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px;
  }

  h3 {
    font-size: 26px;
    margin-bottom: 20px;
  }

  .title {
    text-align: center;

    h2 {
      font-size: 48px;
      margin-bottom: 20px;
    }

    p {
      font-size: 18px;
    }
  }

  .list {
    padding: 0 60px;
    margin: 60px 0;

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      li {
        width: 50%;

        &:before {
          content: "•";
          margin-right: 15px;
        }
      }
    }
  }

  .text {

    h3 {
      text-align: center;
    }

    p {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: $screen__s) {

    .list {
      padding: 0;

      h3,
      h4 {
        text-align: center;
      }

      ul {

        li {
          width: 100%;
        }
      }
    }
  }
}