.form.wishlist.items {
    .actions-toolbar {
        @extend .abs-reset-left-margin;
    }
}

.product-info-main,
.product-options-bottom,
.block-bundle-summary {
    .action.towishlist {
        @extend .abs-action-addto-product;
    }
}

.product-items,
.products.list.items,
.table-comparison {
    .action {
        &.towishlist {
            @extend .abs-actions-addto-gridlist;
            @include lib-icon-font-symbol(
                $icon-wishlist-empty
            );
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    thead > tr > th {
        border-bottom: 0;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }

    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }

    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }

    .col {
        &.item {
            width: 50%;
        }

        &.photo {
            max-width: 150px;
        }

        &.selector {
            max-width: 15px;
        }
    }

    textarea {
        margin: $indent__s 0;
    }

    .input-text.qty {
        margin-bottom: $indent__s;
    }

    .action.primary {
        vertical-align: top;
    }

    .price {
        font-weight: $font-weight__bold;
    }
}

.wishlist-index-index {

    .wishlist-toolbar {
        margin-bottom: 20px;

        .pager {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .toolbar-amount {
            padding: 0;
        }

        p,
        span {
            font-size: 14px;
        }

        .control-select {

            select {
                padding: 0 22px 0 10px;
                height: 28px;
                font-size: 13px;
            }

            &:after {
                width: 22px;
                height: 28px;
                background-size: 16px 16px;
                right: 6px;
            }
        }
    }

    .products-grid.wishlist {
        margin-right: 0;

        .product {

            &-items {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                border-top: 1px solid $c-chromatic2;
                border-bottom: 1px solid $c-chromatic2;
                margin-left: 0;
            }

            &-item {
                padding: 30px 0;

                &-photo {
                    float: none;
                    margin: 0;

                    span,
                    img {
                        width: 100% !important;
                    }
                }

                &-name,
                .price-box {
                    margin-left: 0;
                }

                &-inner {
                    display: flex;
                    flex-direction: column;

                    .box-tocart,
                    .btn-remove {
                        float: none;
                    }

                    .box-tocart {
                        margin-right: 0;
                        margin-top: 20px;
                    }
                }

                &-actions {

                    .actions-primary {
                        display: block;

                        button {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .actions-toolbar {

        .primary {
            display: flex;

            button {
                width: auto;
            }

            button + button {
                margin-left: 16px;
            }
        }
    }
}

.block-wishlist {
    .block-title {
        @extend .abs-block-title;
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }
}

.products-grid.wishlist {
    .product {
        &-item {
            margin-left: 0;
            padding: 0;
            //width: 100%;
            width: 49%;

            &:nth-child(3n + 1) {
                margin-left: 0;
            }

            &-photo {
                display: block;
                margin-bottom: 16px;
            }

            &-name {
                margin-top: 0;
            }

            .price-box {
                margin: 0;
            }

            .comment-box {
                .label {
                    @extend .abs-visually-hidden;
                }
            }

            &-comment {
                display: block;
                height: 42px;
                margin: $indent__s 0;
            }

            &-actions {
                > * {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .box-tocart {
                input.qty {
                    @extend .abs-input-qty;
                    height: 32px;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-grid.wishlist {
        .product-item {
            border-bottom: 1px solid $secondary__color;
            &:first-child {
                border-top: 1px solid $secondary__color;
            }
        }
    }

    body.account {

        .page-main {

            .actions-toolbar {
                flex-direction: column;

                .primary {
                    width: 100%;
                    order: 1;
                    flex-direction: column;
                }

                button {
                    width: 100%;
                    margin-bottom: 16px !important;
                }

                button + button {
                    margin-left: 0;
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .products-grid.wishlist {
        margin-bottom: $indent__l;
        margin-right: -$indent__s;
        .product {
            &-item {
                padding: $indent__base $indent__s $indent__base $indent__base;
                position: relative;
                margin-bottom: 0;

                &-photo {
                    float: left;
                    margin-right: $indent__base;
                }

                &-name {
                    @include lib-font-size(16);
                }

                &-actions {
                    display: block;

                    .action {
                        margin-right: 15px;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.edit {
                            float: left;
                        }

                        &.delete {
                            display: inline-block;
                            margin-top: 16px;
                        }

                        &.edit,
                        &.delete {
                            margin-top: 7px;
                        }
                    }
                }

                &-name,
                &-description,
                .price-box,
                &-tooltip {
                    margin-left: 95px;
                }

                .box-tocart {
                    float: left;
                    margin-right: $indent__base;

                    .stock {
                        margin-top: 7px;
                    }
                }

                .giftregisty-dropdown,
                .field.qty {
                    display: none;
                }
            }
        }
    }

    //
    //  Grid view for wishlist
    //  -----------------------------------------

    .wishlist-index-index {
        .product {
            &-item {

                &-info {
                    width: auto;
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .wishlist-index-index {
        .products-grid {
            .product-item {
                margin-bottom: $indent__base;
                padding: 0;
                width: 49%;
            }

            .product-item-actions {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .products-grid.wishlist {
        .product {
            &-item {
                width: 32%;

                &-tooltip {
                    display: inline-block;
                }

                &-actions {
                    margin: $indent__s 0 0;
                }

                .fieldset {
                    .field.qty {
                        margin-bottom: $indent__s;
                        padding-right: $indent__s;

                        .label {
                            width: auto;
                        }
                    }
                }

                .box-tocart {
                    .actions-primary {
                        margin: 0;
                    }

                    .stock {
                        margin: $indent__base 0 0;
                    }
                }
            }
        }
    }

    .wishlist-index-index {
        .product {
            &-item {
                padding: 0;
            }
        }

        .main {
            .form-wishlist-items {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .wishlist-index-index {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                padding: 0;
                width: 23.5%;
            }
        }
    }
}
