$block-spacing: 140px;
$block-spacing-sm: 80px;

@mixin flex-banner-positions {
    // Default to center center
    align-items: center;
    justify-content: center;

    &--top-left {
        align-items: flex-start;
        justify-content: flex-start;
    }

    &--center-left {
        align-items: flex-start;
        justify-content: center;
    }

    &--bottom-left {
        align-items: flex-start;
        justify-content: flex-end;
    }

    &--top-center {
        align-items: center;
        justify-content: flex-start;
    }

    &--bottom-center {
        align-items: center;
        justify-content: flex-end;
    }

    &--top-right {
        align-items: flex-end;
        justify-content: flex-start;
    }

    &--center-right {
        align-items: flex-end;
        justify-content: center;
    }

    &--bottom-right {
        align-items: flex-end;
        justify-content: flex-end;
    }

    @media screen and (max-width: $screen__m) {

        &--top-left {
            align-items: center;
            justify-content: flex-start;
        }

        &--center-left {
            align-items: center;
            justify-content: center;
        }

        &--bottom-left {
            align-items: center;
            justify-content: flex-end;
        }

        &--top-center {
            align-items: center;
            justify-content: flex-start;
        }

        &--bottom-center {
            align-items: center;
            justify-content: flex-end;
        }

        &--top-right {
            align-items: center;
            justify-content: flex-start;
        }

        &--center-right {
            align-items: center;
            justify-content: center;
        }

        &--bottom-right {
            align-items: center;
            justify-content: flex-end;
        }
    }
}

@mixin mobile-background-position {
    background-position: center center;

    &.mobile-background--top-left {
        background-position: top left;
    }

    &.mobile-background--top-center {
        background-position: top center;
    }

    &.mobile-background--top-right {
        background-position: top right;
    }

    &.mobile-background--center-left {
        background-position: center left;
    }

    &.mobile-background--center-right {
        background-position: center right;
    }

    &.mobile-background--bottom-left {
        background-position: bottom left;
    }

    &.mobile-background--bottom-center {
        background-position: bottom center;
    }

    &.mobile-background--bottom-right {
        background-position: bottom right;
    }
}

// Slider banner module
// --------------------------------------------------
.s_slider-banner {

    &__slide {
        max-height: 990px;
        min-height: calc(100vh - 150px);
        padding: 60px;
        display: flex;
        flex-direction: column;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;

        @include flex-banner-positions;
    }

    &__text {
        text-align: left;
        display: none;
        padding: 20px 50px;

        &--left {
            @extend .s_slider-banner__text;
            text-align: left;
        }

        &--right {
            @extend .s_slider-banner__text;
            text-align: right;
        }

        h2 {
            margin-bottom: 40px;
        }
    }

    img.mobile {
        display: none;
    }

    // owl carousel override
    .owl-dots {
        position: absolute;
        bottom: 50px;
        right: 50px;

        .owl-dot {

            &:active {
                box-shadow: none;
            }

            span {
                background-color: $c-black2;
                border: 1px solid transparent;
                transition: background-color 0.24s ease, border-color 0.24s ease, transform 0.24s ease;
                width: 8px;
                height: 8px;
            }

            &.active {

                span {
                    background-color: transparent;
                    border-color: $c-black2;
                    transform: scale(1.6);
                }
            }

            &:hover {

                span {
                    background-color: $c-black2;
                }
            }
        }
    }

    &.owl-carousel {
        .s_slider-banner__text {
            display: block;
        }

        .owl-nav.disabled {
            display: none !important;
        }
    }

    @media screen and (max-width: $screen__m) {

        &__slide {
            @include mobile-background-position;
            background-image: none !important;
            min-height: unset;
            padding: 0
        }

        &__text {
            text-align: center;
            padding: 30px;

            h2 {
                margin-bottom: 20px;
            }
        }

        .owl-dots {
            right: 50%;
            transform: translateX(50%);
            bottom: -15px;

            button {
                margin: 15px 0;
            }
        }

        img.mobile {
            display: block;
        }

        img.desktop {
            display: none;
        }
    }

    @media screen and (max-width: $screen__s) {

        &__text {
            padding: 60px 30px;
        }

        img.mobile {
            top: -6px;
            position: relative;
        }
    }
}

// Sales banner module
// --------------------------------------------------
.s_sales-banner {
    max-height: 990px;
    min-height: calc(100vh - 48px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    &__text {
        position: absolute;
        width: 50%;
        padding: 120px 10%;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);

        p {
            color: #fff;
        }
    }

    .name {
        font-size: 4rem;
        position: relative;
        font-family: $f-raleway;
    }

    .h1 {
        font-size: 10rem;
        font-family: $f-roboto;
        line-height: 1;
    }

    .notice {
        margin-top: 10px;
        font-size: 2rem;
        font-family: $f-raleway;
    }

    .code {
        font-size: 1.6rem;
        margin-bottom: 10px;
    }

    .cta {

        a {
            margin-right: 20px;
        }
    }

    img.mobile {
        display: none;
    }

    @media screen and (max-width: 1150px) {

        .h1 {
            font-size: 7rem;
        }
    }

    @media screen and (max-width: $screen__m) {
        background-image: none !important;
        min-height: 0;

        &__text {
            width: 100%;
            top: 50%;
            padding: 30px 30px;
        }

        .h1 {
            font-size: 3.6rem;
        }

        .name {
            font-size: 2.4rem;
        }

        .bottom {
            margin-top: 15px;
        }

        img.mobile {
            display: block;
        }
    }

    @media screen and (max-width: $screen__xs) {

        &__text {
            top: 40%;
        }
    }
}

// Upsell module (3 grey blocks)
// --------------------------------------------------
.s_upsell-utils {
    display: flex;
    margin: 60px auto $block-spacing auto;

    &__block {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        padding: 21px 47px;
        margin: 0 24px;
        text-align: center;
        background-color: $c-chromatic1;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    .label,
    a {
        margin: 0 15px;
    }

    .label {
        font-family: $f-roboto-medium;
    }

    a {
        white-space: nowrap;
        color: $c-chromatic5;
        text-decoration: underline;
        transition: color 0.24s ease;
        font-family: $f-roboto;

        &:hover,
        &:active {
            color: $c-chromatic6;
        }
    }

    @media screen and (max-width: $screen__l) {

        &__block {
            padding: 15px;
        }
    }

    @media screen and (max-width: $screen__m) {
        flex-direction: column;

        &__block {
            margin: 10px 0;
        }
    }

    @media screen and (max-width: $screen__s) {
        margin: 35px auto $block-spacing-sm auto;
    }
}

// Offset promo module
// --------------------------------------------------
.s_promo-offset {
    display: flex;
    margin: $block-spacing auto;

    &__alpha {
        padding-right: 59px;
        width: 50%;
    }

    &__beta {
        padding-left: 59px;
        text-align: center;
        margin-top: 92px;
        width: 50%;

        .img {
            margin-top: 90px;
        }

        h3 {
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: $screen__l) {

        &__alpha {
            padding-right: 28px;
        }

        &__beta {
            padding-left: 28px;
        }
    }

    @media screen and (max-width: $screen__m) {
        flex-direction: column;

        &__alpha {
            display: none;
            width: 100%;
            margin-bottom: 40px;
            padding-right: 0;
        }

        &__beta {
            width: 100%;
            padding-left: 0;
            margin-top: 0;

            .img {
                margin-top: 34px;
            }
        }
    }

    @media screen and (max-width: $screen__s) {
        margin: $block-spacing-sm auto;
    }
}

// Simple text banner
// --------------------------------------------------
.s_text-banner {
    max-width: 750px;
    margin: $block-spacing auto;
    text-align: center;

    p {
        display: block;
        margin: 20px 0;
    }

    @media screen and (max-width: $screen__s) {
        margin: $block-spacing-sm auto;
    }
}

// Full width images banner
// --------------------------------------------------
.s_image-banner {
    min-height: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $block-spacing auto;
    padding: 60px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    @include flex-banner-positions;

    img.mobile {
        display: none;
    }

    &--bg-right {
        @extend .s_image-banner;
        background-position: center right;
    }

    &--bg-left {
        @extend .s_image-banner;
        background-position: center left;
    }

    &__text {
        text-align: center;

        &--left {
            @extend .s_image-banner__text;
            text-align: left;
        }

        &--right {
            @extend .s_image-banner__text;
            text-align: right;
        }

        p {
            margin: 20px 0;
        }
    }

    @media screen and (max-width: $screen__m) {
        min-height: 280px;
        background-image: none !important;
        flex-direction: column;

        img.mobile {
            display: block;
            margin-bottom: 40px;
        }
    }

    @media screen and (max-width: $screen__s) {
        margin: $block-spacing-sm auto;
        padding: 60px 30px;
    }
}

// Categories
// --------------------------------------------------
.s_promo-categories {
    margin: $block-spacing auto;

    &__title {
        text-align: center;
        margin-bottom: 40px;

        .subtitle {
            text-transform: uppercase;
            font-size: 1.4rem;
            font-family: $f-roboto-medium;
            letter-spacing: 1px;
        }

        h3 {
            margin: 20px 0 30px 0;
        }
    }

    &__container {
        display: flex;
    }

    &__block {
        width: 33%;
        margin: 0 15px;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        h4 {
            margin-top: 30px;
        }

        p {
            display: block;
            margin: 15px 0 20px 0;
        }
    }

    @media screen and (max-width: $screen__m) {

        &__container {
            flex-direction: column;
        }

        &__block {
            width: 100%;
            margin: 20px 0;

            img {
                max-width: none;
                width: 100%;
            }
        }
    }

    @media screen and (max-width: $screen__s) {
        margin: $block-spacing-sm auto;
    }
}

// Craftsmanship block
// --------------------------------------------------
.s_craftsmanship {
    padding: 100px 0;
    margin: $block-spacing auto 0;
    background-color: $c-chromatic7;

    &__text {
        max-width: 740px;
        margin: 0 auto;
        padding: 0 25px;
        text-align: center;
        color: $c-white;

        svg {
            margin-bottom: 20px;
        }

        p {
            display: block;
            margin: 20px 0;
            padding: 0 20px;
            color: $c-white;
        }
    }

    @media screen and (max-width: $screen__s) {
        margin: $block-spacing-sm auto 0;
    }
}

#size-guide {

    .modal__wrapper {
        max-width: 1000px;
    }
}

.size-guide {



    .size-guide-container {
        max-width: 100%;
        margin: 0 auto;
    }

    .title {

        h2 {
            text-transform: uppercase;
            font-family: $f-roboto;
            letter-spacing: 0.25rem;
            font-size: 2.4rem;
            text-align: center;
        }
    }

    .table {
        margin-top: 42px;
    }

    .table-title {
        margin-bottom: 15px;

        h3 {
            font-family: $f-roboto-bold;
            font-size: 1.8rem;
            text-transform: uppercase;
        }
    }

    .grid {
        display: grid;
        border-top: 1px solid $c-chromatic2;
        border-left: 1px solid $c-chromatic2;
        background-color: $c-white;

        &.six-columns {
            grid-template-columns: repeat(6, 1fr);
        }

        &.four-columns {
            grid-template-columns: repeat(4, 1fr);
        }

        &.seven-rows {
            grid-template-rows: repeat(14, 25px);
        }

        &.six-rows {
            grid-template-rows: repeat(12, 30px);
        }

        > div {
            grid-row: auto / span 2;
            border-right: 1px solid $c-chromatic2;
            border-bottom: 1px solid $c-chromatic2;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
        }

        .header {

            p {
                font-size: 1.8rem;
                font-family: $f-roboto-medium;
                color: $c-black;
            }
        }

        .size {

            p {
                font-size: 1.5rem;
                color: $c-chromatic7;
            }
        }

        .ready {

            p {
                line-height: 1;
                font-size: 1.2rem;
            }
        }

        .bg {
            background-color: $c-white2;
        }

        .span-one-and-a-half {
            grid-row: auto / span 3;
        }

        .span-two-and-a-half {
            grid-row: auto / span 5;
        }
    }

    @media screen and (max-width: $screen__xs) {

        .grid {

            &.six-rows {
                grid-template-rows: repeat(12, 35px);
            }
        }
    }
}
