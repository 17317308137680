.modal-popup.amgdprcookie-bar-container {
    padding: 20px 10px;

    .amgdprcookie-policy {
        font-size: 13px;

        a {
            font-size: 13px;
            font-weight: 600;
            text-decoration: underline;

            &:hover {
                color: #121215;
            }
        }
    }

    .amgdprcookie-buttons-block {
        .amgdprcookie-button {
            text-transform: capitalize;
            color: #121215;
            padding: 15px !important;
            font-size: 13px !important;

            &.-allow,
            &.-save {
                background: #121215;
                color: #fff;
            }

            &.-settings {
                text-decoration: underline;
            }

            &:active,
            &:focus {
                box-shadow: none;
            }
        }
    }

    .action-close {
        top: 0;
        right: 0;
        padding: 0 !important;
    }
}

.modal-popup.amgdprcookie-groups-modal {
    .action-close {
        display: block;
        padding: 0 !important;
    }

    .amgdprcookie-main-wrapper {
        color: #121215;
    }

    .amgdprcookie-cookie-container {
        .amgdprcookie-options {
            color: #a0a0a0;
        }

        .amgdprcookie-text {
            color: #121215;
        }
    }

    .amgdprcookie-done {
        background-color: #121215 !important;
        transition: none !important;
        box-shadow: none !important;
        text-transform: capitalize;

        &:hover {
            background-color: #121215;
        }
    }

    .amgdprcookie-toggle-cookie .amgdprcookie-input:checked:not([disabled]) + .amgdprcookie-label {
        background: #121215;
    }
}

//
//  Dim background overlay
//  _____________________________________________

.cookie-overlay-classic {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: .75;
    z-index: 900;
}

.modal-popup.amgdprcookie-bar-container:before {
    content: '';
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: rgba(0, 0, 0, .75);
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .modal-popup.amgdprcookie-bar-container {

        .amgdprcookie-policy {
            margin-bottom: 0;
            max-width: unset;
            width: 60%;
            display: inline-block;
            padding-left: 20px;
        }

        .amgdprcookie-buttons-block {
            display: inline-block;
            width: 30%;
            float: right;

            .amgdprcookie-button {
                margin-top: 0;
            }
        }
    }
}
