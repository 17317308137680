//  Newsletter subscription

//
//  Desktop
//  _____________________________________________
.footer.content {

  .block {

    &.newsletter {
      float: none;
      padding-top: 140px;
      padding-bottom: 140px;
      padding-left: $container-padding;
      padding-right: $container-padding;

      @media screen and (max-width: $screen__s) {
        padding-top: 80px;
        padding-bottom: 80px;
        padding-left: $container-padding / 2;
        padding-right: $container-padding / 2;
      }
    }

    .newsletter {

      &__title {
        text-align: center;
        margin-bottom: 14px;
        font-size: 27px;
        font-weight: 400;

        @media screen and (max-width: $screen__s) {
          font-size: 24px;
        }
      }

      &__sub-title {
        display: block;
        text-align: center;
        font-size: 16px;
        color: $c-chromatic5;

        @media screen and (max-width: $screen__s) {
          font-size: 14px;
        }
      }

      &__content {
        max-width: 530px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }

      &__label {
        @include abs-visually-hidden;
      }

      &__form {
        display: flex;
        margin-top: 70px;
        padding-bottom: 6px;
        border-bottom: 1px solid $c-black;
      }

      &__field {
        padding: 0;
        flex-grow: 1;

        .control {
          position: relative;

          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url('../images/icons/mail.svg');
            background-repeat: no-repeat;
            width: 24px;
            height: 17px;
          }

          #newsletter-error {
            position: absolute;
            top: 100%;
            left: 0;
            margin-top: 16px;
          }
        }
      }

      &__input {
        display: inline-block;
        max-width: 670px;
        background-color: transparent;
        border: 0;
        padding-left: 36px;

        &:focus {
          border: none;
          box-shadow: none;
        }

        @media screen and (max-width: $screen__s) {
          font-size: 14px;
        }
      }

      &__action {
        border: 0;
        margin: 0;
        padding-left: 16px;
        padding-right: 0;
        border-radius: 0;
        background-color: $c-white;
        text-align: left;
        text-transform: uppercase;
        color: $c-black;
        font-size: 14px;
        font-weight: 500;

        &:hover {
          color: inherit;
        }
      }
    }

    @media screen and (max-width: $screen__m) {

      &__title {
        font-size: 24px;
      }

      &__sub-title {
        font-size: 15px;
      }
    }
  }
}

@media screen and (max-width: $screen__l) {

  .block {

    .newsletter {

      &__actions {
        max-width: 160px;
      }
    }
  }
}

@media screen and (max-width: $screen__xs) {

  .block {

    .newsletter {

      &__form {
        padding-bottom: 0 !important;
        margin-top: 46px;
      }

      &__field {
        box-sizing: border-box;
        background-color: transparent;
        padding: 0;
        margin-bottom: 0;
      }

      &__input {
        padding: 14px 10px 14px 36px;
        border: 1px solid $c-black;
      }

      &__actions {
        max-width: none;
      }

      &__action {
        text-align: center;
        //border-radius: $form-element__border-radius;
        padding: 15px 4px !important;
      }
    }
  }
}


//  Newsletter Landing Page
.newsletter-lp {
  .n-main-section {
    display: flex;
    align-items: center;

    .s_image-banner--bg-right {
      width: 50%;
      margin: 0 0 10px 0;
      min-height: 550px;
      background-position-x: left;

      @media screen and (max-width: $screen__s) {
        display: none;
      }

      img.mobile {
        margin-bottom: 0;
      }

      .s_image-banner__text {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 30px 60px 0px 60px;

        @media screen and (max-width: $screen__s) {
          width: 100%;
        }

        h3, p {
          color:#FFF;
        }
      }
    }

    .n-form {
      width: 50%;

      @media screen and (max-width: $screen__s) {
        width: 100%;
      }
      .subscribe-widget {
          margin: 0;
          padding: 0 25px;

          .group {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
              flex-direction: column;
              gap: 15px;

              @media screen and (max-width: $screen__s) {
                  flex-direction: column;
              }
              .action.actions-toolbar {
                  width: 80%;

                  @media screen and (max-width: $screen__s) {
                    width: 100%;
                  }
                  .primary {
                    width: 100%;
                  }
              }
              .field {
                  position: relative;
                  margin: 0;
                  max-width: 80%;
                  width: 100%;

                  @media screen and (max-width: $screen__s) {
                    max-width: 90%;
                  }

                  @media screen and (max-width: $screen__s) {
                      margin-right: 0;
                      margin-bottom: 30px;
                  }

                  input[type="text"] {
                    border-color: #111;

                    &::placeholder {
                      color: #666;
                    }
                  }
              }
              .group {
                margin: 0;
                max-width: 80%;

                @media screen and (max-width: $screen__s) {
                  max-width: 90%;
                }
              }
          }
      }
    }
  }
    .thumbnails {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        width: 100%;

        @media screen and (max-width: $screen__s) {
            flex-direction: column;
            align-items: center;
        }
        .thumb {
            width: 25%;
            height: 400px;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @media screen and (max-width: $screen__s) {
                width: 100%;
            }
            .overlay {
                background-color: rgba(0, 0, 0, 0.3);
                width: 90%;
                height: 90%;
            }
            .text {
                color: #fff;
                width: 100%;
                height: 100%;
                border: 1px solid #fff;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transition: transform .2s;
                &:hover {
                    transform: scale(0.95);
                }
            }
            .title {
                position: relative;
                font-size: 25px;
                margin-bottom: 25px;
                &:after {
                    content: "";
                    display: block;
                    width: 100px;
                    border-bottom: 1px solid #fff;
                    margin: 25px auto 0;
                }
            }
            .subtitle {
                font-size: 20px;
            }
        }
    }
}

//  Newsletter Success Page
.newsletter-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    width: 100%;
    margin: 10% auto 0;
    padding: 25px;
    text-align: center;

    .subtitle {
      margin-bottom: 20px;
    }
}