.swatch-attribute-label {
    font-weight: bold;
    position: relative;

    &:after {
        content: ":";
    }
}

.swatch-attribute-label.required {
    padding-right: 10px;
}

.swatch-attribute-label[data-required="1"]:after {
    content: '*';
    color: red;
    position: absolute;
    right: -11px;
    top: -2px;
    font-weight: bold;
    font-size: 1em;
}

.swatch-attribute-selected-option {
    color: $c-black2;
    padding-left: 5px;
}

.swatch-attribute-options {
    margin-top: 5px;

    &:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
}

.swatch-option {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    display: inline-block;
    cursor: pointer;
    overflow: hidden;

    &[option-tooltip-value="#ffffff"] {
        border: 2px solid $c-chromatic3;
    }

    img {
        width: 100%;
    }
}

.swatch-option.selected {
    position: relative;

    &:before {
        border-color: $c-white !important;
    }
}

.swatch-option.image:not(.disabled),
.swatch-option.color:not(.disabled) {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        border: 2px solid transparent;
        border-radius: 100%;
        transition: border-color 0.25s ease;
    }

    &:hover {

        &:before {
            border-color: $c-white;
        }
    }
}

.swatch-option.text {
    flex: 1 1 calc(25% - 8px);
    height: auto;
    width: auto;
    padding: 12px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    border-radius: 3px;
    transition: border-color 0.24s ease, background-color 0.24s ease, color 0.24s ease;

    background-color: $c-white;
    color: $c-black2;
    border: 1px solid $c-chromatic3;

    &[data-child-product-stock="0"] {
        background-color: $c-white2;
        color: $c-chromatic5;

        &.selected {
            background-color: $c-chromatic4;
            border: 1px solid $c-chromatic4;
        }
    }

    &.selected {
        border-color: $c-black2;
        background-color: $c-black2;
        color: $c-white;

        &:before {
            content: none;
        }
    }

    &:hover {
        position: relative;
        z-index: 10;
        border-color: $c-black2;
    }
}

.sidebar-main {

    [data-amshopby-filter="size"] {
        width: 100% !important;
        padding: 0 !important;
        overflow: visible !important;
    }

    .size {
        display: grid;
        grid-template-columns: repeat(6, 44px);
        grid-template-rows: 44px;
        grid-gap: 1px;
        box-sizing: border-box;
        position: relative;
        left: 1px;
        top: -1px;

        .am-swatch-link {
            display: block;
            height: 100%;
        }

        .swatch-option.text {
            display: block;
            height: 100%;
            width: 100%;
            padding: 0;
            margin-right: 0;
            margin-bottom: 0;
            border-radius: 0;
            box-shadow: 0 0 0 1px $c-chromatic3;
            border: none;
            line-height: 42px;

            &:hover {
                border: none !important;
                box-shadow: 0 0 0 1px $c-black;
            }
        }
    }
}

.swatch-attribute.size {

    .size-guide-link {
        float: right;
        padding-right: 8px;

        a {
            @extend .s_a-grey;
        }

        &--mobile {
            display: none;
        }
    }

    .swatch-attribute-options {
        display: flex;
        flex-wrap: wrap;

        a + a {
            margin-left: -1px !important;
        }
    }
}

.swatch-attribute.amount {

    .swatch-attribute-options {
        display: flex;
        flex-wrap: wrap;

        .swatch-option {
            flex: 1 1 calc(33.333% - 8px);
        }
    }
}

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-attribute.manufacturer .swatch-option {
    background: rgb(240, 240, 240);
    color: rgb(148, 148, 148);
}

.swatch-attribute.manufacturer .swatch-option.selected {
    color: black;
    background: #fff;
    border: 1px solid #fff;
}

.swatch-option.disabled {
    cursor: default;
}

.swatch-option-tooltip {
    max-width: 140px;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    position: absolute;
    padding: 5px;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    text-align: center;
}

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    left: 40%;
    position: absolute;
    bottom: 0;
    height: 8px;
}

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    content: '';
    position: relative;
    top: 1px;
    left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    content: '';
    position: relative;
    top: 2px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #adadad transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 130px;
    width: 130px;
    margin: 0 auto;
}

.swatch-option-tooltip .title {
    max-width: 140px;
    min-height: 20px;
    max-height: 200px;
    color: rgb(40, 40, 40);
    text-align: center;
    display: block;
    overflow: hidden;
}

.swatch-opt {
    margin: 30px 0 0;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}

.swatch-option-loading {
    content: url(../images/icons/loader.svg);
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}

.color-swatch-list {
    display: flex;
    flex-wrap: wrap;

    li {
        margin: 10px 15px 0 0;
    }
}
