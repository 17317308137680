
button,
.button {
    display: inline-block;
    padding: 15px 45px !important;
    margin: 30px 0;
    color: $c-white;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 1 !important;
    border-radius: 0;
    background-color: $c-black2;
    font-family: $f-roboto;
    transition: color 0.24s ease, background-color 0.24s ease;
    border: 2px solid $c-black2;

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: $c-chromatic7;
        color: $c-white;
        border: 2px solid $c-chromatic7;
    }

    &--transparent-white {
        background-color: transparent;
        border: solid 2px $c-chromatic3;
        color: $c-black2;
        padding: 14px 45px !important;
        transition: color 0.24s ease, background-color 0.24s ease;

        &:hover,
        &:focus {
            border: solid 2px $c-chromatic3;
            color: $c-black2;
            background-color: $c-chromatic3;
            border-color: $c-chromatic3;
        }
    }

    &--transparent-black {
        background-color: transparent;
        border: solid 2px $c-black;
        color: $c-black;
        transition: color 0.24s ease, background-color 0.24s ease;

        &:hover,
        &:focus {
            border-width: 2px;
            background-color: $c-black;
            color: $c-white;
        }
    }

    &.action-dismiss {
        background-color: $c-chromatic3;
        color: $c-black;
        transition: background-color 0.24s ease;

        &:hover {
            background-color: $c-chromatic4;
        }
    }

    @media screen and (max-width: $screen__m) {
        padding: 10px 15px;
        @include lib-font-size(10px);
    }
}
