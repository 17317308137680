
.widget.block-products-list {

    .block-title {
        text-align: center;
        margin-bottom: 40px;

        strong {
            font-size: 4.4rem;
            font-family: $f-raleway;
        }
    }

    .product-items {
        margin-left: 0;
    }

    .product-item {
        padding: 0 6px;
    }

    .product-image-container {
        width: 100% !important;
    }

    .product-image-photo {
        position: static;
        width: 100%;
    }

    .product-image-wrapper {
        padding-bottom: 0 !important;
        height: auto;
        overflow: visible;
    }

    .owl-nav {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 40px;
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translateY(-50%);

        [class*=owl-] {
            pointer-events: all;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 100%;
            width: 38px;
            margin: 0;
            height: 38px;
            text-align: center;
            font-size: 3.5rem !important;

            &:hover {
                color: $c-black;
                background: rgba(255, 255, 255, 1);
            }

            span {
                display: block;
                height: 38px;
            }
        }
    }
}

@media screen and (max-width: 560px) {

    .widget.block-products-list {

        .product-item {
            padding: 0 3px;
        }

        .owl-nav {
            padding: 0 11px;
        }
    }
}
