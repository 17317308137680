@font-face {
  font-family: 'ralewayregular';
  src: url('../fonts/raleway/Raleway-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway/Raleway-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway/Raleway-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/raleway/Raleway-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway/Raleway-Regular.svg#camptonbook') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ralewaybold';
  src: url('../fonts/raleway/Raleway-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/raleway/Raleway-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/raleway/Raleway-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/raleway/Raleway-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/raleway/Raleway-Bold.svg#camptonmedium') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('../fonts/roboto/Roboto-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/Roboto-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/roboto/Roboto-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/Roboto-Regular.svg#camptonsemibold') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
  src: url('../fonts/roboto/Roboto-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/Roboto-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/Roboto-Medium.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/roboto/Roboto-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/Roboto-Medium.svg#camptonsemibold') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotobold';
  src: url('../fonts/roboto/Roboto-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/Roboto-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/Roboto-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('../fonts/roboto/Roboto-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/Roboto-Bold.svg#camptonsemibold') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;
}