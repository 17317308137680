.form.send.friend {
    @extend .abs-add-fields;
    max-width: 500px;
    margin-top: 100px;
}

.action.mailto.friend {
    margin-left: -7px;
    @include lib-icon-font(
        $_icon-font-content: $icon-envelope,
        $_icon-font-size: 28px,
        $_icon-font-line-height: 28px,
        $_icon-font-vertical-align: top
    );

    span {
        display: inline-block;
        line-height: 28px;
        vertical-align: top;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .form.send.friend {
        @extend .abs-add-fields-desktop;
    }
}


// custom
.form.send.friend .fieldset .field:not(.choice) .control {
    width: 100%;
}

.form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary .action.add {
    width: 100%;
    margin-top: 0;
}

.form.send.friend .fieldset .actions-toolbar:not(:first-child) > .secondary {
    float: none;
    width: 100%;
}

.form.send.friend .fieldset .additional .action.remove {
    margin-left: 0;
    position: static;
}

.form.send.friend {

    > .actions-toolbar {
        flex-direction: row-reverse;

        .action.back {

            &:before {
                content: url('../images/icons/link-arrow.svg');
                margin-right: 10px;
                vertical-align: sub;
            }
        }
    }
}

#recipients-options {

    > .fields {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    .fieldset {
        display: flex;
        margin-bottom: 0;
    }

    .fields.additional {
        margin-top: 0;
        position: relative;

        .actions-toolbar {
            position: absolute;
            right: 0;
            width: auto;
            top: 50%;
            transform: translate(100%, -50%);

            .action.remove {
                padding: 15px !important;
            }
        }
    }

    .field {
        width: 50%;
        margin-bottom: 0;

        &:first-of-type {
            margin-right: 15px;
        }
    }
}