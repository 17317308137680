//
//  Shopping cart
//  ---------------------------------------------

//  Summary block
.cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    @include lib-css(background, $sidebar__background-color);
    margin-bottom: $indent__m;
    padding: 1px 15px $indent__m;

    > .title {
        display: block;
        @include lib-heading(h3);
    }

    .block {
        @extend .abs-discount-block;
        margin-bottom: 0;

        .item-options {
            margin-left: 0;
        }

        .fieldset {
            margin: 15px 0 $indent__m $indent__xs;

            .field {
                margin: 0 0 $indent__s;

                &.note {
                    font-size: $font-size__s;
                }
            }

            .methods {
                .field {
                    > .label {
                        display: inline;
                    }
                }
            }
        }

        .fieldset.estimate {
            > .legend,
            > .legend + br {
                @extend .abs-no-display;
            }
        }
    }
    .actions-toolbar {
        > .primary {
            button {
                @extend .abs-revert-secondary-color;
            }
        }
    }
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    border-top: 1px solid $border-color__base;
    padding-top: $indent__s;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;
    }
}

//  Products table
.cart.table-wrapper {
    .items {
        thead + .item {
            border-top: $border-width__base solid $border-color__base;
        }

        > .item {
            border-bottom: $border-width__base solid $border-color__base;
            position: relative;
        }
    }

    .col {
        padding-top: $indent__base;

        &.qty {
            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
            }

            .label {
                @extend .abs-visually-hidden;
            }
        }
    }

    .item {
        &-actions td {
            padding-bottom: $indent__s;
            text-align: center;
            white-space: normal;
        }

        .col {
            &.item {
                display: block;
                min-height: 75px;
                padding: $indent__m 0 $indent__s 75px;
                position: relative;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;

        > .action {
            @extend button;
            @include lib-link-as-button();
            margin-bottom: $indent__s;
            margin-right: $indent__s;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .action {
        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {
        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            font-weight: $font-weight__regular;
            margin-top: -6px;
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        font-size: $font-size__s;
        margin-bottom: $indent__s;
    }

    .product-item-name + .item-options {
        margin-top: $indent__s;
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        text-align: center;

        .action.primary {
            @extend .abs-button-l;
            width: 100%;
        }

        .item {
            & + .item {
                margin-top: $indent__base;
            }
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }
}

.cart-products-toolbar-top {
    border-bottom: $border-width__base solid $border-color__base;
}

//
//  Cross sell
//  ---------------------------------------------

.block.crosssell {
    margin-top: 70px;
}

.cart-tax-info,
.cart .cart-tax-info {
    + .cart-tax-total {
        display: block;
    }
}

.cart.table-wrapper,
.order-items.table-wrapper {
    .col.price,
    .col.qty,
    .col.subtotal,
    .col.msrp {
        text-align: right;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            thead {
                .col {
                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {
                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &.msrp {
                    white-space: normal;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                }
            }
        }
    }

    .cart-container {
        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 23%;

        .actions-toolbar {
            .column.main & {
                @extend .abs-reset-left-margin-desktop;
                > .secondary {
                    float: none;
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {
                .col {
                    &.item {
                        padding: 27px 8px $indent__s;
                    }
                }

                &-actions {
                    td {
                        text-align: right;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }

            .item-actions {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                    text-align: left;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;

            &.action-edit {
                display: none;
            }
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {
        .cart-products-toolbar {
            + .cart {
                thead {
                    tr {
                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {
            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }
}


// SHAN BEGINNING ------------------------------------------------
.abs-shopping-cart-items .action.update,
.block-cart-failed .action.update,
.cart-container .form-cart .action.update {
    padding: 0 !important;
    background-color: transparent;
    color: $c-chromatic5;
    border: none;
    margin-top: 10px;
    margin-bottom: 0;

    &:before {
        content: none;
    }
}

.cart .action.action-edit {
    display: none !important;
}

.cart-totals .totals-tax-summary .mark,
.cart-totals .totals-tax-summary .amount {
    border-top: 0;
    border-bottom: 0;
    padding-top: 20px;

    .price {
        padding-right: 0;

        &:after {
            content: none !important;
        }
    }
}

.s_cart-title {
    margin-top: 80px;
    margin-bottom: 50px;
    text-align: center;

    h1 {
        margin-bottom: 15px;
        font-size: 40px;
        text-transform: uppercase;
    }
}

.cart.table-wrapper {

    .product-item-name a {
        font-size: 1.8rem;
    }

    .price-including-tax,
    .price-excluding-tax {
        font-size: 1.4rem;

        .price {
            font-weight: normal;
        }
    }

    div.price {
        margin-bottom: 20px;
    }

    .items {

        .col.qty,
        .col.subtotal {
            text-align: center;
            vertical-align: middle;
        }

        .col.subtotal {

            .price-including-tax,
            .price-excluding-tax {
                font-size: 1.6rem;
            }
        }

        .col.actions {
            max-width: 20px;
            padding-right: 0;
            vertical-align: middle;
        }

        .input-text.qty {
            min-width: 100px;
            margin-top: 0;
        }
    }

    .item-options {
        font-size: 1.4rem;
        color: $c-chromatic5;

        dt {
            margin-right: 5px;
        }

        dt, dd {
            margin-bottom: 3px;
        }
    }
}

.s_cart-bottom {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
    margin-top: 25px;

    .block.discount {
        flex-basis: 50%;
        max-width: 460px;
        padding-right: 30px;
    }

    // COUPON CODE
    .fieldset.coupon {
        display: flex;
        align-items: flex-end;
        padding-top: 8px;

        .label {
            margin-bottom: 5px;
            font-size: 1.4rem;
            text-transform: none;
            letter-spacing: 0;
        }

        .field {
            margin-bottom: 0;
            width: 60%;
            //flex-shrink: 0;
        }

        .actions-toolbar {
            //flex-shrink: 0;
            width: calc(40% - 20px);
            margin-left: 20px;

            .primary {
                width: 100%;

                .action {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
            }
        }
    }

    // CART TOTALS
    .cart-totals {
        border-top: 0;
        padding-top: 0;
        max-width: 440px;
        flex-basis: 50%;

        .totals {

            th, td {
                color: $c-chromatic5;
                font-size: 1.6rem;
            }

            &.grand {

                th, td {
                    color: $c-black2;
                    font-size: 2.2rem;
                    font-family: $f-roboto-bold;
                }
            }
        }
    }

    .s_cart-summary {

        .s_a-grey {
            display: block;
            margin-top: 30px;
            text-align: center;
            color: $c-black;
            text-decoration: underline;
        }
    }

    .s_cart-actions {
        display: flex;
        margin-top: 30px;

        a {
            flex-basis: 50%;
            text-align: center;
            white-space: nowrap;
        }

        a:first-of-type {
            margin-right: 10px;
        }
    }
}

.block.shipping {
    background-color: $c-white2;
    padding: 60px 0;

    .legend {
        text-align: center;
        float: none;
        font-size: 2.4rem;
    }

    .s_shipping-form {
        display: flex;

        .field {
            width: 33%;
            padding: 0 15px;
        }
    }

    .items.methods {
        max-width: 400px;
        margin: 25px auto 0;

        dt {
            float: right;
        }

        dl {
            float: left;
        }
    }
}

.cart-empty {
    text-align: center;
    margin-top: 200px;
    padding: 0 30px;

    h1 {
        font-size: 3.2rem;
    }

    p {
        max-width: 400px;
        display: block;
        margin: 0 auto 46px;
    }

    @media screen and (max-width: $screen__m) {
        margin-top: 100px;

        h1 {
            margin-bottom: 25px;
        }
    }
}

@media screen and (max-width: $screen__m) {

    .s_cart-title {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .cart.table-wrapper {

        tr.item-info {
            position: relative;
        }

        .items {

            .col.actions {
                position: absolute;
                right: 0;
                top: 48px;
                padding: 12px;
                max-width: none;
            }

            .col.qty,
            .col.subtotal {
                width: 50%;

                &:before {
                    content: none;
                }
            }

            .col.qty {
                float: right;
                text-align: right;
                max-width: 120px;
                padding-bottom: 25px;
            }

            .col.subtotal {
                float: left;
                text-align: left;
                padding-top: 35px;
            }
        }
    }

    .s_cart-bottom {
        flex-direction: column;

        .block.discount,
        .cart-totals {
            flex-basis: 100%;
            max-width: none;
            padding-right: 0;
        }

        .cart-totals {
            margin-top: 30px;
        }

        .s_cart-actions {
            display: flex;
            margin-top: 30px;

            a {
                flex-basis: 50%;
                text-align: center;
                white-space: nowrap;
            }

            a:first-of-type {
                margin-right: 10px;
            }
        }
    }

    .block.shipping {

        .s_shipping-form {
            flex-direction: column;

            .field {
                width: 100%;
                padding: 0;
            }
        }
    }
}

@media screen and (max-width: $screen__xs) {

    .s_cart-bottom {

        .s_cart-actions {
            flex-direction: column;

            a {
                flex-basis: 100%;
            }

            a:first-of-type {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }
}
