//
//  Variables
//  _____________________________________________

$form-field__vertical-indent__desktop: 29px !default;
$form-calendar-icon__color: $primary__color !default;

.fieldset {
  @include lib-form-fieldset();

  &:last-child {
    margin-bottom: 0;
  }

  > .field,
  > .fields > .field {
    @include lib-form-field();

    &.no-label {
      > .label {
        @extend .abs-visually-hidden;
      }
    }

    &.choice {
      .label {
        display: inline;
        font-weight: normal;
      }
    }

    .label {
      .column:not(.main) & {
        font-weight: normal;
      }
    }

    .field.choice {
      margin-bottom: $indent__s;

      &:last-child {
        margin-bottom: 0;
      }
    }

    input[type=file] {
      margin: $indent__xs 0;
    }
  }
}

.legend + .fieldset,
.legend + div {
  clear: both;
}

.legend {
  strong {
    margin-left: $indent__xs;
  }
}

fieldset.field {
  border: 0;
  padding: 0;
}

.field {
  &.date {
    @extend .abs-field-date;

    .time-picker {
      display: inline-block;
      margin-top: $indent__s;
      white-space: nowrap;
    }
  }

  .message {
    &.warning {
      margin-top: $indent__s;
    }
  }
}

.field-error,
div.mage-error[generated] {
  margin-top: 7px;
}

.field-error {
  @include lib-form-validation-note();
}

.field .tooltip {
  @include lib-tooltip(right);

  .tooltip-content {
    min-width: 200px;
    white-space: normal;
  }
}

input,
select {
  &:focus ~ .tooltip .tooltip-content {
    display: block;
  }
}

._has-datepicker {
  ~ .ui-datepicker-trigger {
    @include lib-button-reset();
    @include lib-icon-font(
            $_icon-font-content: $icon-calendar,
            $_icon-font-color: $primary__color__lighter,
            $_icon-font-size: $icon-calendar__font-size,
            $_icon-font-line-height: $icon-calendar__font-size,
            $_icon-font-display: block,
            $_icon-font-text-hide: true
    );
    display: inline-block;
    vertical-align: middle;

    &:focus {
      box-shadow: none;
      outline: 0;
    }
  }
}

//
//  Sidebar forms
//  -----------------------------------------

.sidebar {
  .fieldset {
    margin: 0;

    & > .field:not(.choice),
    .fields > .field {
      &:not(:last-child) {
        margin: 0 0 $form-field__vertical-indent;
      }

      .label {
        margin: 0 0 4px;
        padding: 0 0 $indent__xs;
        text-align: left;
        width: 100%;
      }

      .control {
        width: 100%;
      }
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .fieldset {
    .legend {
      @extend .abs-margin-for-forms-desktop;
    }

    > .field {
      margin: 0 0 $form-field__vertical-indent__desktop;
    }
  }
}

// ------------------------------------------------
// SHAN start
// ------------------------------------------------

.actions-toolbar {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:after,
  &:before {
    content: none;
  }

  .primary,
  .secondary {
    float: none;
    margin-bottom: 0;

    .action,
    a {
      margin: 0;
    }

    a.back {

      &:before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        background-image: url('../images/svg/prev.svg');
        background-position: center center;
        background-size: 24px 24px;
      }

      &:hover:before {
        background-image: url('../images/svg/prev-dark.svg');
      }
    }
  }
}

.fieldset > .field > .label,
.fieldset > .fields > .field > .label {
  @include lib-font-size(14px);
  font-family: $f-roboto-medium;
  cursor: pointer;
  font-weight: normal;
}

.fieldset > .field .addon textarea,
.fieldset > .field .addon select,
.fieldset > .field .addon input,
.fieldset > .fields > .field .addon textarea,
.fieldset > .fields > .field .addon select,
.fieldset > .fields > .field .addon input {
  order: unset;
  width: 100%;
}

.control.captcha-image {
  margin-top: 15px;

  .captcha-img {
    margin-bottom: 0;
  }
}

input[type="checkbox"] + .label,
input[type="radio"] + .label {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-size: 1.4rem !important;
}

input[type="text"],
input[type="password"],
input[type="url"],
input[type="tel"],
input[type="search"],
input[type="number"],
input[type="datetime"],
textarea,
input[type="email"] {
  padding: 10px 15px;
  border-radius: 4px;
  border-color: $c-chromatic3;
  font-size: 16px;
  height: 44px;

  &:focus {
    border-color: $c-black2;
    box-shadow: none !important;
  }
}

input[type="checkbox"],
input[type="radio"] {
  position: relative;
  margin-right: 5px;
  vertical-align: top;
  width: 22px;
  height: 22px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &:before {
    content: "";
    display: block;
    height: 22px;
    width: 22px;
    position: absolute;
    left: 0px;
    top: 0;
    border: none;
    background-color: $c-white;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }
}

.password {

  .control {
    position: relative;

    input {
      padding-right: 30px;
    }

    .show-password {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;
      -webkit-background-size: cover;
      background-size: cover;
      height: 16px;
      transition: transform .12s ease-in, opacity .12s ease-in;
      cursor: pointer;
      color: $c-chromatic5;
      font-size: 1.3rem;
      
      &:hover {
        transform: translateY(-50%) scale(1.05);
      }

      &.shown {
        opacity: .65;
      }
    }
  }
}

input[type="checkbox"] {

  &:before {
    background-image: url("../images/icons/checkbox.svg");
  }

  &:checked:before,
  &.active:before {
    background-image: url("../images/icons/checkbox-checked.svg");
  }
}

input[type="radio"] {
  font-size: 1.2rem;

  &:before {
    left: -1px;
    border-radius: 100%;
    background-image: url("../images/icons/radio.svg");
  }

  &:checked:before,
  &.active:before {
    background-image: url("../images/icons/radio-checked.svg");
    border-color: $c-black;
  }
}

.control-select {
  position: relative;


  &.disabled {
    background-color: $c-chromatic1;
    border-color: $c-chromatic2;
    pointer-events: none;

    &:after {
      opacity: 0;
    }

    select {
      color: $c-chromatic3;
    }
  }

  select {
    padding: 10px 40px 10px 15px;
    height: 42px;
    font-size: 1.6rem;
    box-shadow: none;
    background-image: none;
    appearance: none;
    background-color: $c-white;
    border: 1px solid $c-chromatic3;
    border-radius: 4px;
  }

  &:after {
    content: "";
    display: block;
    width: 42px;
    height: 42px;
    top: 0;
    right: 0;
    position: absolute;
    background-image: url("../images/icons/select-expand.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    pointer-events: none;
  }
}

// ------------------------------------------------
// Degaspe end
// ------------------------------------------------