.footer.content {
  border-top: none;
  margin-top: 0;
  padding-top: 0;
  max-width: none;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;

  ul {

    > li {
      margin-bottom: 10px;
    }

    a {
      color: $c-black2;
      font-size: 16px;
      font-family: $f-roboto;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .footer {

    &__main {
      display: flex;
      @extend .s_container--small;
      padding-top: 70px;
      padding-bottom: 70px;
      font-size: 16px;
      flex-wrap: wrap;

      &__goup {
        @extend .s_container--large;
        position: absolute;
        top: 0;
        left: 0;
        right: 30px;

        #goup {
          text-transform: uppercase;
          color: $c-white;
          position: absolute;
          top: 0;
          right: 0;
          background-color: $c-black2;
          padding: 18px 28px;
          cursor: pointer;
          transition: transform .24s ease-in-out;

          span {
            display: block;
            font-size: 16px;
            letter-spacing: 1px;
            font-family: $f-roboto;
            font-weight: 500;
          }

          &:hover {
            transform: translateY(-4px);
          }
        }
      }

      &__container {
        background-color: $c-white2;
        position: relative;
      }

      &__info {
        width: 32%;

        a {
          color: $c-black2;
          font-size: 16px;
          font-family: $f-roboto;

          &:hover {
            text-decoration: none;
          }
        }

        a[href*="tel"] {
          text-decoration: underline;
        }
      }

      &__links {
        display: flex;
        width: 68%;
        justify-content: space-between;
      }

      &__social {
        position: relative;
        width: 100%;
        display: flex;
        padding-top: 70px !important;

        &:after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          bottom: 8px;
          height: 1px;
          width: calc(100% - 228px);
          background-color: $c-black2;
        }

        li {
          margin-bottom: 0 !important;
        }

        li + li {
          padding-left: 20px;
        }

        a {
          display: block;
          width: 18px;
          height: 18px;
        }

        svg {
          width: 18px;
          height: 18px;
        }
      }

      @media screen and (max-width: $screen__l) {

        &__goup {
          display: none;
        }
      }

      @media screen and (max-width: $screen__s) {
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 50px;

        &__info {
          width: 100%;
        }

        &__links {
          width: 100%;
          flex-direction: column;
          flex-wrap: wrap;

          ul {
            width: 100%;
            margin-top: 30px;
          }
        }

        &__social {
          justify-content: center;

          &:after {
            content: none;
          }
        }
      }
    }

    &__small-links {
      background-color: $c-chromatic1;
      padding: 32px 0;

      &__container {
        @extend .s_container--large;
        position: relative;
        display: flex;
        align-items: center;
      }

      &__logo {
        margin-right: 36px;

        a {
          display: block;
          height: 16px;
        }
      }

      .copyright {
        color: $c-chromatic5;
        font-size: 14px;
        margin-top: 0;
        margin-right: 14px;
      }

      &__misc {
        display: flex;
        padding-right: 120px;

        a {
          display: block;
          color: $c-chromatic5;
          font-size: 14px;
          font-family: $f-roboto;

          &:hover {
            text-decoration: none;
          }
        }

        a + a {
          margin-left: 14px;
        }
      }

      .switcher-language {
        margin-left: auto;
        font-size: 14px;
      }

      @media screen and (max-width: $screen__m) {

        &__container {
          flex-direction: column;
          align-items: center;
        }

        &__logo {
          margin-bottom: 14px;
          margin-right: 0;
        }

        .copyright {
          margin-bottom: 12px;
          font-size: 13px;
          margin-right: 0;
        }

        &__misc {
          padding-right: 0;
          margin-bottom: 28px;

          a {
            font-size: 13px;
          }
        }

        .switcher-language {
          position: static;
          transform: none;
        }
      }
    }
  }
}

