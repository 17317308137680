#size-guide {
	background: #fff;
	.modal__wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
	.size-guide-container {
		display: flex;

		table {
			tr,td {
				border: 1px solid #ccc;
				text-align: center;
				vertical-align: middle;
			}
		}
		.right-content.blockSize {
			margin-left: 20px;
			.product.data.items {
				.item.content {
					padding: 20px 0;
				}
				>.item.title {
					>.switch {
						border: none;
						background: #fff;
					}
				}
			}
			h3 {
				margin-bottom: 20px;
			}
			.item.title.active {
				border-bottom: 2px solid #000;
				padding-bottom: 10px;
			}
		}
	}
}
@media only screen and (max-width: 768px) {
	#size-guide {
		.size-guide-container {
			flex-direction: column;
			.right-content.blockSize {
				margin: 15px;
				h3 {
					margin-bottom: 10px;
				}

				table {
					tr,td {
						font-size: 10px;
					}
				}

				.product.data.items {
					margin-bottom: 0;
					>.item.title {
						display: inline;
						>.switch {
							display: inline;
							font-size: 14px;
							line-height: 30px;
							height: 30px;
						}
					}
					.item.content {
						border-top: 1px solid #ccc;
						border-bottom: none;
						border-right: none;
						border-left: none;
					}
				}
				.item.title.active {
					padding-bottom: 7px;
				}
			}
		}
	}
	.modal#size-guide {
		.modal__wrapper {
			position: relative;
    		height: auto;

    		.modal__content.size-guide {
    			padding: 0;
    		}
		}
	}
}
